import React, { useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { Layout , AlertContext } from '../widgets'
import * as routes from '../routes'
import {
  OrderableItems,
  AdminUserCell,
} from './components'
import {
  Loading,
  withRouter,
} from '../shared'
import { NavigationTab } from '../components'

const OrderableItemsWithRouter = withRouter(OrderableItems)

const ShowcaseOrderableItems = ({state, setState, save, deleteItem, props}) => {
  const itemRow = (item, index, moveActions) => {
    const body = <h3 className='discover-row-content-body center'>{item.body}</h3>
    const button = <h5 className='discover-row-content-button center'>{item.button}</h5>
    return (
      <div key={index} className='discover-row'>
        <div className='discover-row-content'>
          <img src={item.image} alt='' />
          <pre className='discover-row-content-link'>{item.link}</pre>
          <h1 className='discover-row-content-title center'>{item.title}</h1>
          {item.body ? body : button}
        </div>
        {moveActions}
      </div>
    )
  }

  return <OrderableItemsWithRouter
      state={state}
      setState={setState}
      getItemId={(item) => item.id}
      itemRow={itemRow}
      save={save}
      newItem={() => props.router.navigate(routes.dynamic(routes.adminDiscoverNewPath, props.router.params.type))}
      showDeleteButton={(item) => item.position === undefined}
      delete={deleteItem}
    />

}

const BrowseAllOrderableItems = ({state, setState, save, deleteItem, props}) => {
  const itemRow = (item, index, moveActions) => {
    return (
      <div key={index} className='discover-row'>
        <div className='discover-row-content'>
          <img src={item.image} alt='' />
          <pre className='discover-row-content-id'>{item.id}</pre>
          <pre className='discover-row-content-link'>{item.link}</pre>
          <h1 className='discover-row-content-title'>{item.title}</h1>
          <h3 className='discover-row-content-body'>{item.body}</h3>
        </div>
        {moveActions}
      </div>
    )
  }

  return <OrderableItemsWithRouter
      state={state}
      setState={setState}
      getItemId={(item) => item.id}
      itemRow={itemRow}
      save={save}
      newItem={() => props.router.navigate(routes.dynamic(routes.adminDiscoverNewPath, props.router.params.type))}
      showDeleteButton={(item) => item.position === undefined}
      delete={deleteItem}
    />

}

const EffectImageOrderableItems = ({state, setState, save, deleteItem, props}) => {
  const itemRow = (item, index, moveActions) => {
    return (
      <div key={index} className='discover-row'>
        <div className='discover-row-content'>
          <img src={item.image} alt='' />
          <pre className='discover-row-content-id'>{item.id}</pre>
        </div>
        {moveActions}
      </div>
    )
  }

  return <OrderableItemsWithRouter
      state={state}
      setState={setState}
      getItemId={(item) => item.id}
      itemRow={itemRow}
      save={save}
      newItem={() => props.router.navigate(routes.dynamic(routes.adminDiscoverNewPath, props.router.params.type))}
      showDeleteButton={(item) => item.position === undefined}
      delete={deleteItem}
    />

}

const EffectOrderableItems = ({state, setState, save, deleteItem, props}) => {
  const itemRow = (item, index, moveActions) => {
    return (
      <div key={index} className='discover-row'>
        <div className='discover-row-content'>
          <img src={item.image} alt='' />
          <pre className='discover-row-content-id'>{item.id}</pre>
          <pre className='discover-row-content-link'>{item.link}</pre>
          <h1 className='discover-row-content-title'>{item.title}</h1>
          <h3 className='discover-row-content-body'>{item.body}</h3>
        </div>
        {moveActions}
      </div>
    )
  }

  return <OrderableItemsWithRouter
      state={state}
      setState={setState}
      getItemId={(item) => item.id}
      itemRow={itemRow}
      save={save}
      newItem={() => props.router.navigate(routes.dynamic(routes.adminDiscoverNewPath, props.router.params.type))}
      showDeleteButton={(item) => item.position === undefined}
      delete={deleteItem}
    />

}

const GenreOrderableItems = ({state, setState, save, deleteItem, props}) => {
  const itemRow = (item, index, moveActions) => {
    return (
      <div key={index} className='discover-row'>
        <div className='discover-row-content'>
          <img src={item.image} alt='' />
          <pre className='discover-row-content-id'>{item.id}</pre>
          <pre className='discover-row-content-link'>{item.link}</pre>
          <h1 className='discover-row-content-title'>{item.title}</h1>
        </div>
        {moveActions}
      </div>
    )
  }

  return <OrderableItemsWithRouter
      state={state}
      setState={setState}
      getItemId={(item) => item.id}
      itemRow={itemRow}
      save={save}
      newItem={() => props.router.navigate(routes.dynamic(routes.adminDiscoverNewPath, props.router.params.type))}
      showDeleteButton={(item) => item.position === undefined}
      delete={deleteItem}
    />

}

const ArtistOrderableItems = ({state, setState, save, deleteItem, props}) => {
  const itemRow = (item, index, moveActions) => {
    return (
      <div key={index} className='discover-row'>
        <AdminUserCell key={index} user={item.data} />
        {moveActions}
      </div>
    )
  }

  return <OrderableItemsWithRouter
      state={state}
      setState={setState}
      getItemId={(item) => item.id}
      itemRow={itemRow}
      save={save}
      newItem={() => props.router.navigate(routes.dynamic(routes.adminDiscoverNewPath, props.router.params.type))}
      showDeleteButton={(item) => item.position === undefined}
      delete={deleteItem}
    />

}

const ProducerOrderableItems = ({state, setState, save, deleteItem, props}) => {
  const itemRow = (item, index, moveActions) => {
    return (
      <div key={index} className='discover-row'>
        <AdminUserCell key={index} user={item.data} />
        {moveActions}
      </div>
    )
  }

  return <OrderableItemsWithRouter
      state={state}
      setState={setState}
      getItemId={(item) => item.id}
      itemRow={itemRow}
      save={save}
      newItem={() => props.router.navigate(routes.dynamic(routes.adminDiscoverNewPath, props.router.params.type))}
      showDeleteButton={(item) => item.position === undefined}
      delete={deleteItem}
    />

}

const defaultState = {
  loading: true,

  // OrderableItems
  items: {},
  activeIds: [],
  inactiveIds: [],
}

const AdminDiscover = (props) => {

  const [state, setState] = useState(defaultState)
  const { showToast, showAlertConfirm, handleError } = useContext(AlertContext)

  const itemType = () => {
    return props.router.params.type
  }

  const mergeState = (subState) => {
    setState(state => {
      return {...state, ...subState}
    })
  }

  useEffect(() => {
    load(itemType())
  }, [])

  const handleTabChange = (tab) => {
    props.router.navigate(routes.dynamic(routes.adminDiscoverPath, tab), { replace: true })
    load(tab)
  }

  const save = () => {
    axios.put(routes.dynamic(routes.adminDiscoverSetOrderUrl, itemType()), {
      ids: state.activeIds
    }).then((response) => {
      load(itemType())
      showToast('Changes Saved!')
    }).catch((error) => {
      handleError(error)
    })
  }

  const deleteItem = (item) => {
    showAlertConfirm({ title: 'Delete Item', callback: () => {
      mergeState({ loading: true })
      axios.delete(
        routes.dynamic(routes.adminDiscoverDeleteUrl, itemType(), item.id)
      ).then((response) => {
        load(itemType())
      }).catch((error) => {
        handleError(error)
      }).finally(() => {
        mergeState({ loading: false })
      })
    }})
  }

  const load = (itemType) => {
    mergeState(defaultState)
    axios
      .get(routes.dynamic(routes.adminDiscoverGetUrl, itemType))
      .then((response) => {
        const discover = response.data

        const items = discover.reduce((out, item) => {
          out[item.id] = item
          return out
        }, {})
        const activeIds = discover.filter((item) => 'position' in item).map((item) => item.id)
        const inactiveIds = discover.filter((item) => !('position' in item)).map((item) => item.id)

        mergeState({
          items,
          activeIds,
          inactiveIds
        })
      }).catch((error) => {
        handleError(error)
      }).finally(() => {
        mergeState({ loading: false })
      })
  }

  if (state.loading) {
    return <Loading />
  }

  var content = null
  const contentProps = {
    load: load,
    state: state,
    setState: (args, callback) => {
      mergeState(args)
      if (typeof callback === 'function') {
        callback()
      }
    },
    save: save,
    deleteItem: deleteItem,
    props: props,
  }

  switch (itemType()) {
    case 'showcase':
      content = <ShowcaseOrderableItems {...contentProps} />
      break
    case 'browse_all':
      content = <BrowseAllOrderableItems {...contentProps} />
      break
    case 'effect_image':
      content = <EffectImageOrderableItems {...contentProps} />
      break
    case 'effect':
      content = <EffectOrderableItems {...contentProps} />
      break
    case 'genre':
      content = <GenreOrderableItems {...contentProps} />
      break
    case 'artist':
      content = <ArtistOrderableItems {...contentProps} />
      break
    case 'producer':
      content = <ProducerOrderableItems {...contentProps} />
      break
    default:
      Sentry.captureMessage(`Unknown discover type: ${itemType()}`)
  }

  const navigationTab =
    <Layout>
      <Layout.Header>Discover</Layout.Header>
      <div className='moderator-content'>
        <div className='navigation-tabs'>
          <NavigationTab
            isActive={itemType() === 'showcase'}
            onClick={() => { handleTabChange('showcase') }}
            text='Showcase'
          />
          <NavigationTab
            isActive={itemType() === 'browse_all'}
            onClick={() => { handleTabChange('browse_all') }}
            text='Browse All'
          />
          <NavigationTab
            isActive={itemType() === 'effect_image'}
            onClick={() => { handleTabChange('effect_image') }}
            text='Effect Image'
          />
          <NavigationTab
            isActive={itemType() === 'effect'}
            onClick={() => { handleTabChange('effect') }}
            text='Effects'
          />
          <NavigationTab
            isActive={itemType() === 'genre'}
            onClick={() => { handleTabChange('genre') }}
            text='Genres'
          />
          <NavigationTab
            isActive={itemType() === 'artist'}
            onClick={() => { handleTabChange('artist') }}
            text='Artists'
          />
          <NavigationTab
            isActive={itemType() === 'producer'}
            onClick={() => { handleTabChange('producer') }}
            text='Producers'
          />
        </div>
        <div className='discover-content'>
          {content}
        </div>
      </div>
    </Layout>

  return (
    <div className='body-container'>
      {navigationTab}
    </div>
  )
}

export default withRouter(AdminDiscover)
