import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Input, Layout, AlertContext } from '../widgets'
import * as routes from '../routes'
import {
  AdminPostCell,
  AdminPlaylistCell,
  FilterPicker,
  ItemReportCell,
} from './components'
import {
  arrayDiff,
  Loading,
  withRouter,
} from '../shared'
import { NavigationTab, PaginatedList } from '../components'

const tabs = [
  'Posts',
  'Edit',
  'Reports',
]

const AdminEditPlaylist = (props) => {
  const [currentTab, setTab] = useState(tabs[0])
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(true)

  const [playlist, setPlaylist] = useState(null)
  const [name, setName] = useState(null)
  const [blocks, setBlocks] = useState([])
  const [existingBlockReasons, setExistingBlockReasons] = useState([])
  const [updatedBlockReasons, setUpdatedBlockReasons] = useState([])

  useEffect(() => {
    load()
  }, [])

  const { showAlert, showAlertConfirm, handleError } = useContext(AlertContext)

  const playlistId = () => props.router.params.playlistId

  const load = () => {
    setLoading(true)
    axios.get(
      routes.dynamic(routes.adminPlaylistUrl, playlistId())
    ).then((response) => {
      const playlist = response.data

      axios.get(
        routes.adminModerationBlockUrl, {
          params: {
            item_type: 'PLAYLIST',
            item_id: playlistId(),
          }
        }
      ).then((response) => {
        const blocks = response.data.data
        const reasons = blocks.map((block) => block.reason)

        setPlaylist(playlist)
        setName(playlist.name)
        setBlocks(blocks)
        setExistingBlockReasons(reasons)
      }).catch(handleError).finally(() => {
        setLoading(false)
      })

    }).catch(handleError)
  }

  const onFeature = () => {
    setLoading(true)
    axios.post(routes.adminPlaylistFeaturedUrl, {
      id: playlist.id,
      featured: !playlist.is_featured,
    }).then(load).catch(handleError)
  }

  const onSubmitImageBlock = () => {
    setLoading(true)
    axios.put(routes.adminModerationBlockImageUrl, {
      id: playlistId(),
      type: 'PLAYLIST',
      block: !playlist.is_image_blocked,
    }).catch(handleError).finally(load)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setSubmitting(true)
    axios.patch(routes.dynamic(routes.adminPlaylistUrl, playlistId()), {
      name,
    }).then(() => {
      const toCreate = arrayDiff(updatedBlockReasons, existingBlockReasons)
      const toDelete = arrayDiff(existingBlockReasons, updatedBlockReasons)
      const createRequests = toCreate.map((reason) => {
        return axios.post(routes.adminModerationBlockUrl, {
          item_type: 'PLAYLIST',
          item_id: playlistId(),
          reason: reason,
        })
      })
      const deleteRequests = toDelete.map((reason) => {
        return axios.delete(routes.adminModerationBlockUrl, {
          data: {
            item_type: 'PLAYLIST',
            item_id: playlistId(),
            reason: reason,
          }
        })
      })
      Promise.all(createRequests.concat(deleteRequests)).catch(handleError).then(load)
    }).catch(handleError).finally(() => {
      setSubmitting(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  const blockDetail = blocks?.map(block => block.details).join('\n')

  return (
    <div className='body-container'>
      <Layout>
        <Layout.Header>
          {playlist.name}
        </Layout.Header>

        <AdminPlaylistCell playlist={playlist} noline>
          <AdminPlaylistCell.EditActions
            playlist={playlist}
            onFeature={onFeature}
            onBlockImage={onSubmitImageBlock}
          />
        </AdminPlaylistCell>

        <div className='navigation-tabs'>
          {tabs.map((tab, i) =>
            <NavigationTab
              key={i}
              text={tab}
              isActive={currentTab === tab}
              onClick={() => {
                if (currentTab !== tab) {
                  setTab(tab)
                }
              }}
            />
          )}
        </div>

        {currentTab === 'Posts' &&
          <PaginatedList key={'posts'}
            loadUrl={routes.dynamic(routes.adminPlaylistItemUrl, playlistId())}
            featuredUrl={routes.adminPostFeaturedUrl}
            cell={AdminPostCell}
            cellItemPropName={'post'}
            featureActionUpdateBehavior={'update'}
          />
        }

        {currentTab === 'Edit' &&
          <Form layout='vertical' onSubmit={handleSubmit}>
            <div className='submit-track'>
              <div className='submit-track__right'>

                <Form.Item label='Name *'>
                  <Input required type='text' value={name} onChange={(event) => setName(event.target.value)} />
                </Form.Item>

                <FilterPicker.ItemBlock
                  onChange={setUpdatedBlockReasons}
                  defaultValues={existingBlockReasons}
                />

                {blockDetail &&
                  <pre className='left-text font-12'>{blockDetail}</pre>
                }

                <Form.Button
                  htmlType='submit'
                  loading={submitting}
                >
                  Save
                </Form.Button>

              </div>
            </div>
          </Form>
        }

        {currentTab === 'Reports' && <PaginatedList
          loadUrl={routes.adminModerationReportItemUrl}
          loadParams={{
            'item_type': 'PLAYLIST',
            'item_id': playlistId(),
          }}
          cell={ItemReportCell}
          cellItemPropName={'report'}
        />}

      </Layout>
    </div>
  )
}

export default withRouter(AdminEditPlaylist)
