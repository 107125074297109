import { Link } from 'react-router-dom'
import { Button, Layout } from '../widgets'

const BeatUploadsMovingToBeatstars = (props) => {

  const Header = (props) => <Layout.Header>{props.children}</Layout.Header>
  const Heading = (props) => <h5>{props.children}</h5>
  const Body = (props) => <div className='padding-8-0'>{props.children}</div>

  return <div className='body-container left-text'>
    <Layout>
      <Header>
        Upgrade Your Beats with BeatStars
      </Header>
      <div className='layout-content'>
        <Heading>
          Your music, bigger marketplace, better opportunities
        </Heading>
        <Body>
          Exciting news! We're enhancing your beat-sharing experience by partnering with BeatStars, the industry's leading music marketplace.
          You can now showcase your beats on Voloco through BeatStars' powerful platform.
          <br/>

          <ul>
            <li>
              Reach millions of potential buyers worldwide
            </li>
            <li>
              Set your own pricing and licensing terms
            </li>
            <li>
              Keep 100% of your beat sales revenue
            </li>
            <li>
              Get professional marketing tools and analytics
            </li>
            <li>
              Access detailed sales tracking and reporting
            </li>
            <li>
              Benefit from secure payment processing
            </li>
            <li>
              Join a thriving community of producers
            </li>
            <li>
              Gain exposure through BeatStars' promotion features
            </li>
            <li>
              Protect your work with built-in licensing agreements
            </li>
            <li>
              Integrate seamlessly with Voloco
            </li>
            <li>
              Save time by uploading to one platform
            </li>
          </ul>
          <br/>

          <Heading>
            Ready to expand your reach?
          </Heading>
          <br/>
          Create your BeatStars account today and take your music career to the next level! Our integration ensures a smooth transition for all Voloco creators.
          <br/><br/>
          See <a href={'https://help.beatstars.com/hc/en-us/articles/29782332756123-Voloco-Account-Integration-FAQs'}>this article</a> for details on getting started.
          <br/><br/>

          <Link to={'https://www.beatstars.com'} className='display-inline-block'>
            <Button className='large blue width-fit-content'>
              Continue to BeatStars
            </Button>
          </Link>
          <br/><br/>
        </Body>

      </div>
    </Layout>
  </div>
}

export default BeatUploadsMovingToBeatstars
