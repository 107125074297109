import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import * as routes from '../routes'
import { Loading, withRouter, formatStat } from '../shared'
import { AlertContext } from '../widgets'
import {
  AppStoreBanner,
  AudioPlayerContext,
  BeatCell,
  PostCell,
  PlaylistCell,
  PublicUserLink,
  NavigationTab,
  PaginatedList,
} from '../components'
import playImg from '../images/play.svg'
import profileImg from '../images/profile.svg'
import likeImg from '../images/like.svg'

const SocialLink = ({socialLink}) => {
  return socialLink.url &&
    <a href={socialLink.url} target='_blank' rel='noreferrer noopener'>
      {socialLink.service_name}
    </a>
}

const PublicUserView = (props) => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [currentTab, setTab] = useState('Tracks')

  useEffect(() => {
    load()
  }, [])

  const { handleError, showToast } = useContext(AlertContext)
  const { loadAndPlayPost, loadAndPlayBeat, pauseItem, isItemPlaying } = useContext(AudioPlayerContext)

  const getId = () => queryString.parse(props.router.location.search).id

  const load = () => {
    setLoading(true)
    axios.get(routes.producerPublicProfileUrl, {
      params: {
        requested_user_id: getId(),
        include_approvals: false,
      }
    }).then((response) => {
      setUser(response.data)
    }).catch((error) => {
      handleError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  if (user === null) {
    return <>
      <div className='welcome-title'>404</div>
      <div>There's nothing here</div>
    </>
  }

  const {
    username,
    profile: {
      profile_pics,
      bio,
      social_links,
    },
    details: {
      total_plays,
      follower_count,
      like_count,
      beat_count,
      top_track_count,
      playlist_count,
    }
  } = user

  const onShare = (item) => {
    navigator.clipboard.writeText(item.share_url).then(() => {
      showToast('Link copied to clipboard.')
    }, (err) => {
      showToast('Could not copy the track to your clipboard!')
    })
  }

  const onTogglePlayPause = (item, type) => {
    if (!item) { return }
    if (isItemPlaying(item.id)) {
      pauseItem(item.id)
    } else {
      switch (type) {
        case 'POST':
          loadAndPlayPost(item)
          break
        case 'BEAT':
          loadAndPlayBeat(item)
          break
        default:
          break
      }
    }
  }

  const statForTab = (tab) => {
    switch (tab) {
      case 'Tracks': return top_track_count
      case 'Beats': return beat_count
      case 'Playlists': return playlist_count
      default: return -1
    }
  }

  const tabs = {
    'Tracks': <PaginatedList
      key={'post'}
      loadUrl={routes.getPostsPublicUserUrl}
      loadParams={{'requested_user_id': getId()}}
      cell={PostCell}
      cellItemPropName={'post'}
      cellAdditionalProps={{
        audience: 'public',
        onShare: onShare,
        onPlayPause: (post) => onTogglePlayPause(post, 'POST'),
        isPlaying: (post) => isItemPlaying(post.id),
      }}
    />,
    'Beats': <PaginatedList
      key={'beat'}
      loadUrl={routes.getBeatsPublicUserUrl}
      loadParams={{'requested_user_id': getId()}}
      cell={BeatCell}
      cellItemPropName={'beat'}
      cellAdditionalProps={{
        audience: 'public',
        onShare: onShare,
        onPlayPause: (beat) => onTogglePlayPause(beat, 'BEAT'),
        isPlaying: (beat) => isItemPlaying(beat.id),
      }}
    />,
    'Playlists': <PaginatedList
      key={'playlist'}
      loadUrl={routes.dynamic(routes.playlistsForUserUrl, getId())}
      cell={PlaylistCell}
      cellItemPropName={'playlist'}
      cellAdditionalProps={{
        audience: 'public',
        onShare: onShare,
      }}
    />,
  }

  return loading ? <Loading /> : <>
    <AppStoreBanner />

    <div className='public-view-background'>
      <img src={profile_pics['400']} alt=''/>
    </div>

    <div className='public-view-body'>
      <div className='position-relative'>
        <img className='public-view-artwork' src={profile_pics['400']} />
        <div className='public-view-username-overlay'>
          <PublicUserLink user_id={getId()} username={username} />
        </div>
      </div>

      <div className='public-view-stats'>
        <AudioPlayerContext.Stat image={playImg} value={formatStat(total_plays)} />
        <AudioPlayerContext.Stat image={profileImg} value={formatStat(follower_count)} />
        <AudioPlayerContext.Stat image={likeImg} value={formatStat(like_count)} />
      </div>

      <div className='public-user-profile__bio'>{bio}</div>
      <div className='public-user-profile__social'>
        {social_links && social_links.map((socialLink, index) => <SocialLink key={index} socialLink={socialLink} />)}
      </div>

      <div className='navigation-tabs'>
        {Object.keys(tabs).map((tab, i) =>
          <NavigationTab
            key={i}
            text={`${formatStat(statForTab(tab))} ${tab}`}
            isActive={currentTab === tab}
            onClick={() => {
              if (currentTab !== tab) {
                setTab(tab)
              }
            }}
          />
        )}
      </div>

      <div className='width-85vw max-width-600'>
        {tabs[currentTab]}
      </div>
    </div>
  </>
}

export default withRouter(PublicUserView)
