import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/remote-config'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import * as routes from '../routes'
import environment from '../environment'

class AuthClient {
  constructor() {
    this.initialized = false
    this.isGoogleAvailable = false
    this.state = {
      user: {},
      beatstarsPassthrough: null,
    }
  }

  initialize(callback) {
    if (this.initialized) {
      callback()
      return
    }
    this.initialized = true

    // configure firebase
    if (environment() === 'prod') {
      firebase.initializeApp({
        apiKey: 'AIzaSyDKwg7szD3Sfg17cCcwhJwlJKt3J-HVMqk',
        authDomain: 'voloco-156320.firebaseapp.com',
        projectId: 'voloco-156320',
        appId: '1:73035618530:web:7db48d4d628fc0aa2a66bb',
      })
    } else {
      firebase.initializeApp({
        apiKey: 'AIzaSyAKWi76Z7EBLRdOrPg8zwcO5Ye5SxUsceg',
        authDomain: 'voloco-dev.firebaseapp.com',
        projectId: 'voloco-dev',
        appId: '1:173575683994:web:3fbc5e3ced3eaee13b0423'
      })
    }

    // setup remote config
    this.remoteConfig = firebase.remoteConfig()
    this.remoteConfig.defaultConfig = {
      'boost_beat_sku': 'boost_beat_a'
    }
    this.remoteConfig.fetchAndActivate()
      .catch((err) => {
        Sentry.captureException(err)
      })

    // setup some auth stuff
    if (environment() === 'dev') {
      firebase.auth().useEmulator('http://localhost:9099')
    }
    firebase.auth().useDeviceLanguage()

    axios.get(
      'https://apis.google.com/js/api.js'
    ).then((response) => {
      this.isGoogleAvailable = true
    }).catch((error) => {
      this.isGoogleAvailable = false
    }).finally(callback)
  }

  currentUser() {
    return this.state.user
  }

  userId() {
    return this.state.user.user_id
  }

  updateUser(user) {
    this.state.user = {
      user_id: user.user_id || this.userId(),
      username: user.username,
      profile: user.profile
    }
  }

  tryLogin(authUrl) {
    if (this.isLoggedIn()) {
      return Promise.resolve()
    }
    const rezcavToken = AuthClient.getLocalStorageRezcavToken()
    if (rezcavToken && !this.userId()) {
      this.setAuthInterceptor(rezcavToken)

      return new Promise((resolve, reject) => {
        if (authUrl.includes('admin')) {
          const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            this.loginToRezcavWithFirebase(user, authUrl).then(resolve).catch(reject).finally(unsubscribe)
          })
        } else {
          axios.get(
            routes.producerProfileUrl
          ).then((response) => {
            this.updateUser(response.data)
            resolve()
          }).catch((error) => reject())
        }
      })
    }
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        this.loginToRezcavWithFirebase(user, authUrl).then(resolve).catch(reject).finally(unsubscribe)
      })
    })
  }

  isExternallyAuthenticated() {
    return this.initialized && (!!firebase.auth().currentUser || !!this.state.beatstarsPassthrough)
  }

  isLoggedIn() {
    return !!this.userId() && (!!AuthClient.getLocalStorageRezcavToken() || this.isExternallyAuthenticated())
  }

  logout() {
    return new Promise((resolve, reject) => {
      AuthClient.removeLocalStorageRezcavToken()
      this.ejectAuthInterceptor()
      this.state = {
        user: {},
        beatstarsPassthrough: null,
      }
      if (firebase.auth().currentUser) {
        firebase.auth().signOut().then(resolve).catch(reject)
      } else {
        resolve()
      }
    })
  }

  refreshRezcavToken() {
    axios.post(
      refreshTokenUrl
    ).then((res) => {
      const { token } = res.data
      AuthClient.setLocalStorageRezcavToken(token)
      this.setAuthInterceptor(token)
    }).catch((error) => {
      AuthClient.removeLocalStorageRezcavToken()
      this.ejectAuthInterceptor()
    })
  }

  loginToRezcav(resolve, reject, authUrl, type, token, email, name) {
    axios.post(authUrl, {
      type, token, email, name // email and name are required for admin login
    }).then((res) => {
      const { id, user_id, token, username, profile } = res.data
      AuthClient.setLocalStorageRezcavToken(token)
      this.updateUser({
        user_id: id || user_id,
        username: username,
        profile: profile
      })
      this.setAuthInterceptor(token)
      resolve()
    }).catch(reject)
  }

  loginToRezcavWithFirebase(user, authUrl) {
    if (!user || !authUrl) {
      return Promise.reject()
    }
    if (user && this.userId()) {
      return Promise.resolve()
    }
    return new Promise((resolve, reject) => {
      user.getIdToken(false).then((firebaseToken) => {
        this.loginToRezcav(resolve, reject, authUrl, 'FIREBASE', firebaseToken, user.email, user.displayName)
      })
    })
  }

  loginToRezcavWithBeatstars(beatstarsPassthrough, authUrl) {
    if (!beatstarsPassthrough || !authUrl) {
      return Promise.reject()
    }
    if (beatstarsPassthrough && this.userId()) {
      return Promise.resolve()
    }
    return new Promise((resolve, reject) => {
      this.state.beatstarsPassthrough = beatstarsPassthrough
      this.loginToRezcav(resolve, reject, authUrl, 'BEATSTARS', beatstarsPassthrough, null, null)
    })
  }

  signUp(signUpUrl, username, profile) {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        firebase.auth().currentUser.getIdToken(false).then((firebaseToken) => {
          this.signUpImpl(resolve, reject, signUpUrl, 'FIREBASE', firebaseToken, username, profile)
        })
      } else if (this.state.beatstarsPassthrough) {
        this.signUpImpl(resolve, reject, signUpUrl, 'BEATSTARS', this.state.beatstarsPassthrough, username, profile)
      }
    })
  }

  signUpImpl(resolve, reject, signUpUrl, type, token, username, profile) {
    axios.post(signUpUrl, {
      type,
      token,
      username,
      profile: JSON.stringify(profile)
    }).then((res) => {
      const { user_id, token, username, profile } = res.data
      AuthClient.setLocalStorageRezcavToken(token)
      this.updateUser({
        user_id: user_id,
        username: username,
        profile: profile
      })
      this.setAuthInterceptor(token)
      resolve()
    }).catch(reject)
  }

  remoteConfigValue(key) {
    return this.remoteConfig.getValue(key).asString()
  }

  setAuthInterceptor(token) {
    this.ejectAuthInterceptor()
    this.state.authInterceptor = axios.interceptors.request.use((config) => {
      config.headers = Object.assign({}, config.headers, { 'Authorization': `Bearer ${token}` })
      return config
    })
  }

  ejectAuthInterceptor() {
    axios.interceptors.request.eject(this.state.authInterceptor)
  }

  static setLocalStorageRezcavToken(token) {
    window?.localStorage?.setItem('_rct', token)
  }

  static getLocalStorageRezcavToken() {
    return window?.localStorage?.getItem('_rct')
  }

  static removeLocalStorageRezcavToken() {
    window?.localStorage?.removeItem('_rct')
  }
}

export default new AuthClient()
