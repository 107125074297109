import { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

import { Dropdown, Layout, AlertContext } from '../widgets'
import { FeatureFlagContext } from '../widgets/FeatureFlagContext'
import * as routes from '../routes'
import { PaginatedList } from '../components'
import { ConversationCell } from './components'
import { withRouter } from '../shared'
import messageSettings from '../images/message-settings.svg'
import plusSign from '../images/plus-sign.svg'
import classNames from '../widgets/util'
import sadBoi from '../images/sad-boi.svg'

const privacyMap = {
  'ALL': 'Everyone',
  'FOLLOWING': 'People you follow',
  'NONE': 'No one',
}

const ConversationsView = (props) => {
  const { handleError } = useContext(AlertContext)
  const { getFlagValue } = useContext(FeatureFlagContext)
  const [messageSettingLevel, setMessageSettingLevel] = useState('ALL')

  useEffect(() => {
    if (getFlagValue('showDMs')) {
      axios.get(routes.getMessageSettingsUrl).then((response) => {
        setMessageSettingLevel(response.data.privacy)
      }).catch(handleError)
    }
  }, [])

  if (!getFlagValue('showDMs')) {
    return <Layout>
        <div className='welcome-title'>404</div>
        <div>There's nothing here</div>
      </Layout>
  }

  const navigateToConversation = (id) => {
    props.router.navigate(routes.dynamic(routes.producerFullConversationPath, id))
  }

  const updateMessageSettings = (setting) => {
    if (setting === messageSettingLevel) {
      return
    }
    axios.put(routes.getMessageSettingsUrl, {
      privacy: setting
    }).then(() => {
      setMessageSettingLevel(setting)

    }).catch(handleError)
  }

  const messagesSettingsMenuAnchor = <img src={messageSettings} alt='message settings' className='conversation-settings-icon' />

  const messagesSettingsActions = [
    {
      title: 'Allow messages from',
      contentOverride: <div className='widgets-Dropdown-Action messages-settings_header'>Allow messages from</div>,
    },
    {
      title: 'Everyone',
      contentOverride: <div className='messages-settings_item widgets-Dropdown-Action' onClick={() => updateMessageSettings('ALL')}>
        <div>Everyone</div>
        <div className={classNames(messageSettingLevel === 'ALL' ? 'messages-settings_item_selected' : 'messages-settings_item_unselected')}></div>
      </div>,
    },
    {
      title: 'People you follow',
      contentOverride: <div className='messages-settings_item widgets-Dropdown-Action' onClick={() => updateMessageSettings('FOLLOWING')}>
      <div>People you follow</div>
      <div className={classNames(messageSettingLevel === 'FOLLOWING' ? 'messages-settings_item_selected' : 'messages-settings_item_unselected')}></div>
    </div>,
    },
    {
      title: 'No one',
      contentOverride: <div className='messages-settings_item widgets-Dropdown-Action' onClick={() => updateMessageSettings('NONE')}>
      <div>No one</div>
      <div className={classNames(messageSettingLevel === 'NONE' ? 'messages-settings_item_selected' : 'messages-settings_item_unselected')}></div>
    </div>,
    }
  ]

  return <div className='body-container'>
      <Layout>
        <Layout.Header>
          <div className='messages-header'>
            <div className='widgets-Layout-Header'>Messages</div>
            <div className='messages-header_actions'>
              <Dropdown anchor={messagesSettingsMenuAnchor} actions={messagesSettingsActions}/>
              <Link to={routes.producerNewMessagePath} className='messages-header_actions_new-message'>
              <img src={plusSign} alt='new message' /></Link>
            </div>
          </div>
        </Layout.Header>
          <PaginatedList
            key={'conversations'}
            loadUrl={routes.getConversationsUrl}
            cell={(props) => <ConversationCell {...props} navigateToConversation={navigateToConversation} />}
            cellItemPropName={'conversation'}
            emptyStateOverride={<div className='conversation_empty-state'>
              <img src={sadBoi} alt='sad boi' />
              <div>No messages yet. Start a new conversation by tapping the ‘plus’ icon!</div>
            </div>}
          />
      </Layout>
    </div>

}

export default withRouter(ConversationsView)
