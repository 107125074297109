import { useContext, useEffect, useState, useRef } from 'react'
import * as moment from 'moment'
import axios from 'axios'
import { AlertContext } from '../widgets'
import {
  Loading,
} from '../shared'
import classNames from '../widgets/util'

const DatePicker = ({start, onChange}) => {
  return <div className='flex-row margin-vertical-small'>
    <h6>Since</h6>
    <input
      type='date'
      value={start.format('YYYY-MM-DD')}
      min={moment().subtract(14, 'days').endOf('day').format('YYYY-MM-DD')}
      max={moment().endOf('day').format('YYYY-MM-DD')}
      onChange={(event) => onChange(event.target.value)}
    />
  </div>
}

const PaginatedList = (props) => {
  const hasRunOnRender = useRef(false)
  const [state, replaceState] = useState({
    loading: true,
    items: [],
    offset: 0,
    pageState: null,
    startDate: props.datePicker ? moment().subtract(3, 'days').endOf('day') : undefined,
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  // handles the initial load and when the date changes
  useEffect(() => {
    load(state)
  }, [state.startDate])

  useEffect(() => {
    props.itemsUpdated?.(state.items)

    // allows parent component to run a callback after the list has rendered
    if (!state.loading && !hasRunOnRender.current) {
      hasRunOnRender.current = true
      props.onRender?.()
    }
  }, [state.items, state.loading])

  const { handleError } = useContext(AlertContext)

  const onDateChanged = (date) => {
    setState({
      items: [],
      offset: 0,
      pageState: null,
      startDate: moment(date, 'YYYY-MM-DD'),
    })
  }

  const updateItems = (item, updater) => {
    if (props.featureActionUpdateBehavior === 'filter') {
      return state.items.filter(x => x.id !== item.id)
    } else { // update inplace
      return state.items.map(x => {
        if (x.id === item.id) {
          updater(x)
        }
        return x
      })
    }
  }

  const onFeatured = (item) => {
    axios.post(props.featuredUrl, {
      id: item.id,
      featured: !item.is_featured,
    }).then((response) => {
      setState({
        items: updateItems(item, (item) => item.is_featured = !item.is_featured),
      })
    }).catch((error) => {
      handleError(error)
    })
  }

  const didHide = (item) => {
    setState({
      items: state.items.filter(x => x.id !== item.id),
    })
  }

  const load = (state) => {
    if (state.offset < 0) {
      return
    }
    setState({ loading: true })
    const params = {
      page_size: 20,
      offset: state.offset,
      page_state: state.pageState,
      sort: 'DESC',
    }
    if (props.datePicker) {
      params.start = state.startDate.format('YYYY-MM-DD')
      params.end = moment().endOf('day').format('YYYY-MM-DD')
    }

    axios.get(props.loadUrl, {
      params: {...params, ...props.loadParams},
      headers: {...{}, ...props.loadHeaders},
    }).then((response) => {
      setState({
        items: state.items.concat(response.data.data || response.data.beats),
        offset: response.data.next_offset,
        pageState: response.data.page_state,
      })
    }).catch((error) => {
      handleError(error)
    }).finally(() => {
      setState({ loading: false })
    })
  }

  if (state.items.length <= 0 && state.loading) {
    return <Loading />
  }

  const seeMoreButton = (state.offset >= 0 || state.pageState != null) && !state.loading &&
    // eslint-disable-next-line
    <a
      href='#'
      className='float-left margin-16 link'
      onClick={e => {
        e.preventDefault()
        load(state)
      }}
    >
      See More
    </a>

  const emptyState = props.emptyStateOverride ? props.emptyStateOverride : <div className='float-left'>No results</div>

  return <>
    {props.datePicker && <DatePicker
      start={state.startDate}
      onChange={onDateChanged}
    />}

    <div className={classNames(props.reverse && 'flex-column-end')}>
      {state.items.length <= 0 ?
        emptyState :
        <>
          {props.reverse && seeMoreButton}

            {(props.reverse ? state.items.slice().reverse() : state.items).map((item, i) => {
              const CellComponent = props.cell
              return <CellComponent
                key={i}
                {...{
                  [props.cellItemPropName]: item,
                  didHide: didHide
                }}
                {...(props.cellAdditionalProps || {})}
              >
                {CellComponent.FeatureActions &&
                  <CellComponent.FeatureActions {...{
                    [props.cellItemPropName]: item,
                    ...props.featuredUrl && {onFeatureClick: () => onFeatured(item)},
                  }} />
                }
              </CellComponent>
            })}
        </>
      }
      {!props.reverse && seeMoreButton}
    </div>
  </>
}

export default PaginatedList
