const currentOrigin = (() => {
  var origin = null
  return () => {
    if (origin == null) {
      origin = window.location.origin.replace(':3000', ':8080')
    }
    return origin
  }
})()

export const withoutParams = (path) => path.replace(/\?.*$/, '')

export const dynamic = (path, ...values) => {
  var out = path
  for (const value of values) {
    out = out.replace(/:[a-z]+/i, value)
  }
  return out
}

export const rootPath = '/'

// client-side routes
export const applinksPostsPath = '/applinks/posts'
export const applinksPostLink = '/applinks/posts?id=:id'

export const applinksBeatsPath = '/applinks/beats'
export const applinksBeatLink = '/applinks/beats?id=:id'

export const applinksPublicUserProfilePath = '/applinks/creator'
export const applinksPublicUserProfileLink = '/applinks/creator?id=:id'

export const applinksPlaylistPath = '/applinks/playlist'
export const applinksPlaylistLink = '/applinks/playlist?id=:id'

export const purchaseBoostDeepLink = '/applinks/purchase/boost?item_type=:type&item_id=:id'

export const adminRootPath = '/admin'

export const adminBeatsPath = '/admin/beats'
export const adminBeatsEditPath = '/admin/beats/edit/:beatId'

export const adminPostsPath = '/admin/posts'
export const adminPostEditPath = '/admin/track/edit/:postId'

export const adminPlaylistsPath = '/admin/playlists'
export const adminPlaylistEditPath = '/admin/playlists/edit/:playlistId'

export const adminCreatorProfilePath = '/admin/creator/:userId'
export const adminAnalyticsPath = '/admin/analytics'
export const adminSearchPath = '/admin/search'

export const adminDiscoverPath = '/admin/discover/:type'
export const adminDiscoverNewPath = '/admin/discover/:type/new'

export const adminModerationPath = '/admin/moderation'

export const adminBoostListPath = '/admin/boosts'
export const adminConversationPath = '/admin/conversation/:id'

export const adminFeatureFlagsPath = 'admin/feature-flags'

export const producerRootPath = '/producer'
export const producerSignUpPath = '/producer/signup'
export const producerBeatsPath = '/producer/beats'
export const producerBeatsSubmitPath = '/producer/beats/submit'
export const producerBeatsEditPath = '/producer/beats/edit/:beatId'
export const producerTopTracksPath = '/producer/toptracks'
export const producerPlaylistsPath = '/producer/playlists'
export const producerPlaylistsEditPath = '/producer/playlists/edit/:playlistId'
export const producerSubmitPath = '/producer/submit'
export const producerProfileEditPath = '/producer/profile/edit'
export const producerProfilePath = '/producer/profile'
export const producerBeatUploadsMovingToBeatstars = '/producer/beats/beat-uploads-beatstars'
export const producerReportBasePath = '/producer/report'
export const producerReportPath = `${producerReportBasePath}/:type/:id`
export const producerConversationPath = '/producer/conversations'
export const producerFullConversationPath = '/producer/conversation/:id'
export const producerNewMessagePath = '/producer/conversations/new-message'

export const producerPurchaseBeatBoostPath = '/producer/purchase/boost/beat/:id'
export const producerBoostsPath = '/producer/boosts'

// server-side routes
export const adminAuthUrl = `${currentOrigin()}/user/admin/signin`
export const adminAnalyticsUrl = `${currentOrigin()}/analytics/admin`

export const adminSearchBeatsUrl = `${currentOrigin()}/search/admin/beats`
export const adminSearchPostsUrl = `${currentOrigin()}/search/admin/posts`
export const adminSearchUsersUrl = `${currentOrigin()}/search/admin/users`
export const adminSearchReindexBeatsUrl = `${currentOrigin()}/search/reindex/beats`
export const adminSearchReindexPostsUrl = `${currentOrigin()}/search/reindex/posts`
export const adminSearchReindexUsersUrl = `${currentOrigin()}/search/reindex/users`
export const adminSearchStatsBeatsUrl = `${currentOrigin()}/search/stats/beats`
export const adminSearchStatsPostsUrl = `${currentOrigin()}/search/stats/posts`
export const adminSearchStatsUsersUrl = `${currentOrigin()}/search/stats/users`

export const adminDiscoverOptionsUrl = `${currentOrigin()}/discover/admin/:type/options`
export const adminDiscoverCreateUrl = `${currentOrigin()}/discover/admin/:type`
export const adminDiscoverGetUrl = `${currentOrigin()}/discover/admin/:type`
export const adminDiscoverSetOrderUrl = `${currentOrigin()}/discover/admin/:type`
export const adminDiscoverDeleteUrl = `${currentOrigin()}/discover/admin/:type/:id`

export const adminGetBeatUrl = `${currentOrigin()}/beats/admin/:beatId`
export const adminListRecentBeatsUrl = `${currentOrigin()}/beats/recent`

export const adminBeatTrendingUrl = `${currentOrigin()}/beats/admin/trending`
export const adminBeatFeaturedUrl = `${currentOrigin()}/beats/admin/featured`
export const adminBeatFeedUrl = `${currentOrigin()}/beats/admin/feed`
export const adminBeatNewUrl = `${currentOrigin()}/beats/admin/new`
export const adminBeatConvertToPostUrl = `${currentOrigin()}/beats/admin/convert_to_post`
export const adminBeatUserUrl = `${currentOrigin()}/beats/admin/user`

export const adminBoostListUrl = `${currentOrigin()}/boost/admin`
export const adminBoostStatsUrl = `${currentOrigin()}/boost/admin/stats`
export const adminBoostUserUrl = `${currentOrigin()}/boost/admin/user`

export const adminPostUrl = `${currentOrigin()}/post/admin/:postId`

export const adminPostTrendingUrl = `${currentOrigin()}/post/admin/trending`
export const adminPostFeaturedUrl = `${currentOrigin()}/post/admin/featured`
export const adminPostFeedUrl = `${currentOrigin()}/post/admin/feed`
export const adminPostConvertToBeatUrl = `${currentOrigin()}/post/admin/convert_to_beat`
export const adminPostUserUrl = `${currentOrigin()}/post/admin/user`
export const adminPostForYouUrl = `${currentOrigin()}/post/admin/for_you`

export const adminPlaylistUrl = `${currentOrigin()}/playlist/admin/:playlistId`
export const adminPlaylistItemUrl = `${currentOrigin()}/playlist/admin/:playlistId/item`
export const adminPlaylistTrendingUrl = `${currentOrigin()}/playlist/admin/trending`
export const adminPlaylistFeaturedUrl = `${currentOrigin()}/playlist/admin/featured`
export const adminPlaylistUserUrl = `${currentOrigin()}/playlist/admin/user`
export const adminPlaylistFeedUrl = `${currentOrigin()}/playlist/admin/feed`

export const adminModerationBlockUrl = `${currentOrigin()}/moderation/block`

export const adminPostModerationRecognizeUrl = `${currentOrigin()}/moderation/recognize`
export const adminPostModerationRecognizeAllUrl = `${currentOrigin()}/moderation/recognize/all`

export const adminModerationRecognizeAuditUrl = `${currentOrigin()}/moderation/recognize/audit`
export const adminModerationReportUrl = `${currentOrigin()}/moderation/report`
export const adminModerationReportAuditUrl = `${currentOrigin()}/moderation/report/audit`
export const adminModerationReportUpdateUrl = `${currentOrigin()}/moderation/report/update`
export const adminModerationReportItemUrl = `${currentOrigin()}/moderation/report/item`
export const adminModerationReportReasonsUrl = `${currentOrigin()}/moderation/report/reasons`

export const adminModerationUserBanUrl = `${currentOrigin()}/moderation/ban`

export const adminModerationBlockImageUrl = `${currentOrigin()}/moderation/block_image`

export const adminCreatorProfileUrl = `${currentOrigin()}/user/admin/creator/profile`

export const commentListByItemUrl = `${currentOrigin()}/comment`
export const adminCommentByUserUrl = `${currentOrigin()}/comment/admin/:userId`

export const producerSignInUrl = `${currentOrigin()}/user/signin`
export const producerSignUpUrl = `${currentOrigin()}/user/signup`
export const producerDeleteAccountUrl = `${currentOrigin()}/user`
export const producerProfileUrl = `${currentOrigin()}/user/profile`
export const producerPublicProfileUrl = `${currentOrigin()}/user/public/profile`
export const userSocialLinkTemplatesUrl = `${currentOrigin()}/user/social_link_templates`
export const getUrlProfileUrl = `${currentOrigin()}/url/profile`
export const blockUserUrl = `${currentOrigin()}/user/block`
export const refreshTokenUrl = `${currentOrigin()}/user/refresh_token`
export const getMessageSettingsUrl = `${currentOrigin()}/user/settings/conversation`

export const getUrlBeatUrl = `${currentOrigin()}/url/beat`
export const getUrlPostUrl = `${currentOrigin()}/url/post`
export const postBeatsUrl = `${currentOrigin()}/beats`
export const getBeatUrl = `${currentOrigin()}/beats/:beatId`
export const listBeatsUrl = `${currentOrigin()}/beats/listbyid`
export const getBeatsPrivateUserUrl = `${currentOrigin()}/beats/private/user`
export const getBeatsPublicUserUrl = `${currentOrigin()}/beats/public/user`
export const deleteBeatUrl = `${currentOrigin()}/beats`
export const adminPatchBeatUrl = `${currentOrigin()}/beats/admin/:beatId`
export const beatsPublicUserUrl = `${currentOrigin()}/beats/public/user`

export const getConversationsUrl = `${currentOrigin()}/conversation`
export const getConversationInfoUrl = `${currentOrigin()}/conversation/:conversationId`
export const archiveConversationUrl = `${currentOrigin()}/conversation/:conversationId/archive`
export const getFullConversationUrl = `${currentOrigin()}/conversation/:conversationId/message`
export const markMessageReadUrl = `${currentOrigin()}/conversation/:conversationId/message/:messageId/read`
export const newConversationUrl = `${currentOrigin()}/conversation/dm`

export const postPostUrl = `${currentOrigin()}/post`
export const getPostsPrivateUserUrl = `${currentOrigin()}/post/private/user`
export const getPostsPublicUserUrl = `${currentOrigin()}/post/public/user`
export const getPostUrl = `${currentOrigin()}/post/:postId`
export const deletePostUrl = `${currentOrigin()}/post`

export const playlistUrl = `${currentOrigin()}/playlist/:playlistId`
export const playlistsForUserUrl = `${currentOrigin()}/playlist/user/:userId`
export const playlistItemUrl = `${currentOrigin()}/playlist/:playlistId/item`

export const searchBeatsUrl = `${currentOrigin()}/search/beats`
export const searchPostsUrl = `${currentOrigin()}/search/posts`
export const searchUsersUrl = `${currentOrigin()}/search/users`

export const storePublicUrl = `${currentOrigin()}/store/public/13`
export const genreUrl = `${currentOrigin()}/genre`
export const stripePricesUrl = `${currentOrigin()}/purchase/stripe/prices`

export const stripeCheckoutSessionUrl = `${currentOrigin()}/purchase/stripe/checkout-session`
export const producerBoostListUrl = `${currentOrigin()}/boost`

export const adminListConversationsUrl = `${currentOrigin()}/conversation/admin`
export const adminConversationListMessagesUrl = `${currentOrigin()}/conversation/admin/:id/message`
export const adminGetConversationUrl = `${currentOrigin()}/conversation/admin/:id`
