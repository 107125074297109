import { useContext } from 'react'

import axios from 'axios'
import { Layout, AlertContext } from '../widgets'
import { FeatureFlagContext } from '../widgets/FeatureFlagContext'
import * as routes from '../routes'
import { withRouter } from '../shared'
import UserSearch from './UserSearch'

const ConversationsNewMessageView = (props) => {
  const { handleError } = useContext(AlertContext)
  const { getFlagValue } = useContext(FeatureFlagContext)

  if (!getFlagValue('showDMs')) {
    return <Layout>
        <div className='welcome-title'>404</div>
        <div>There's nothing here</div>
      </Layout>
  }

  const startConversationAndNavigate = (id) => {
    axios.post(routes.newConversationUrl, {
      other_user_id: id,
    }).then((response) => {
      props.router.navigate(routes.dynamic(routes.producerFullConversationPath, response.data.id))
    }).catch(handleError)
  }

  return <div className='body-container'>
      <Layout>
        <Layout.Header>
          New Message
        </Layout.Header>
        <div className='new-message-container'>
          <UserSearch onClickUser={startConversationAndNavigate}/>
        </div>
      </Layout>
    </div>

}

export default withRouter(ConversationsNewMessageView)
