import { useState } from 'react'

const CheckboxGroup = (props) => {
  const [checked, setChecked] = useState(new Set(props.defaultValues))

  const handleOnChange = (isChecked, choiceId) => {
    if (props.singleChoice) {
      if (checked.has(choiceId)) {
        return
      }
      checked.clear()
    }
    if (isChecked) {
      checked.add(choiceId)
    } else {
      checked.delete(choiceId)
    }
    setChecked(checked)
    props.onChange(Array.from(checked))
  }

  const type = props.singleChoice ? 'radio' : 'checkbox'

  return <div className='widgets-CheckboxGroup'>
    {props.choices.map((choice, i) => {
      const choiceIdStr = JSON.stringify(choice.id)
      return <div key={i} className='widgets-Checkbox'>
        <input type={type} checked={checked.has(choice.id)} value={choiceIdStr} id={choiceIdStr} onChange={(event) => handleOnChange(event.target.checked, choice.id)} />
        <label htmlFor={choiceIdStr}>{choice.value}</label>
      </div>
    })}
  </div>
}

export default CheckboxGroup
