import React, { lazy, useContext, useEffect, useState } from 'react'
import * as moment from 'moment'
import axios from 'axios'

import * as routes from '../routes'
import { Layout, DateRangePicker, AlertContext } from '../widgets'
import {
  Loading
} from '../shared'

const AdminCharts = lazy(() => import('./components/AdminCharts'))

const AdminAnalytics = (props) => {
  const [state, replaceState] = useState({
    loading: true,
    startDate: moment().utc().subtract(31, 'days'),
    endDate: moment().utc().subtract(1, 'days'),
    charts: [],
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  const { handleError } = useContext(AlertContext)

  const toLocalChartData = (serverData) => {
    // convert the server data to local chart data
    const localData = []
    for (const chartData of serverData) {
      const dataPointsByDay = chartData.dataPoints.reduce((out, dataPoint) => {
        var day = dataPoint.day
        out[day] = out[day] || []
        out[day].push(dataPoint)
        return out
      }, {})
      const dataPointNames = [...new Set(chartData.dataPoints.map(dataPoint => dataPoint.name))]
      const dataPoints = []

      for (var date = state.startDate.clone(); date.diff(state.endDate.endOf('day')) <= 0; date.add(1, 'days')) {
        const day = date.format('M/D/YY')
        const dataPointsOnDay = dataPointsByDay[day] || []
        for (const dataPointName of dataPointNames) {
          if (!dataPointsOnDay.find((dataPoint) => dataPoint.name === dataPointName)) {
            // fill in missing days with zeros
            dataPointsOnDay.push({name: dataPointName, day: day, count: 0})
          }
        }

        // format the data for recharts
        dataPoints.push(dataPointsOnDay.reduce((out, dataPoint) => {
          out[dataPoint.name] = dataPoint.count
          return out
        }, {day: day}))
      }

      localData.push({
        name: chartData.name,
        type: chartData.type,
        dataPoints: dataPoints,
        dataPointNames: dataPointNames,
      })
    }

    // done
    return localData
  }

  const loadData = () => {
    axios.get(routes.adminAnalyticsUrl, {
      params: {
        start: state.startDate.format('YYYY-MM-DD'),
        end: state.endDate.format('YYYY-MM-DD'),
      }
    })
      .then((response) => {
        const data = response.data
        const charts = toLocalChartData(data)
        setState({
          charts: charts
        })
      }).catch((error) => {
        handleError(error)
      }).finally(() => {
        setState({ loading: false })
      })
  }

  const onDateChanged = (stateKey, date) => {
    setState({
      items: [],
      offset: 0,
      [stateKey]: moment(date, 'YYYY-MM-DD').utc(),
    })
  }

  if (state.loading) {
    return <Loading />
  }

  const body =
    <Layout>
      <Layout.Header>Analytics</Layout.Header>
      <DateRangePicker
        minDate={'2018-10-06'}
        startDate={state.startDate}
        endDate={state.endDate}
        onDateChanged={onDateChanged}
        onGo={loadData}
      />
      <AdminCharts charts={state.charts} />
    </Layout>

  return (
    <div className='body-container'>
      {body}
    </div>
  )
}

export default AdminAnalytics
