import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as routes from '../../routes'

export default class UserLink extends Component {
  render() {
    const {
      user_id,
      username,
      disabled
    } = this.props
    return disabled ? username :
      <Link className={this.props.className} to={routes.dynamic(routes.adminCreatorProfilePath, user_id)}>{username ? username : user_id}</Link>

  }
}
