import { useContext, useEffect, useState } from 'react'
import queryString from 'query-string'
import axios from 'axios'
import { Layout, AlertContext } from '../widgets'
import * as routes from '../routes'
import {
  Loading,
  withRouter,
} from '../shared'
import { AudioPlayerContext, BeatCell, PaginatedList } from '../components'

const ProducerBeats = (props) => {
  const [loading, setLoading] = useState(false)
  const [boostPurchaseResult, setBoostPurchaseResult] = useState(queryString.parse(props.router.location.search).boost_purchase_result)

  const { showAlert, showAlertError, showAlertConfirm, showToast, handleError } = useContext(AlertContext)
  const { loadAndPlayBeat, pauseItem, isItemPlaying } = useContext(AudioPlayerContext)

  useEffect(() => {
    if (boostPurchaseResult === 'success') {
      showAlert({
        title: 'Purchase Complete',
        message: <>
          <div>Your beat is now being boosted in the Hot feed!</div>
          <br/>
          <div>You will receive a purchase email confirmation shortly.</div>
        </>,
        callback: () => {
          setBoostPurchaseResult(null)
        }
      })
    }
  }, [boostPurchaseResult])

  const onShare = (beat) => {
    navigator.clipboard.writeText(beat.share_url).then(() => {
      showToast('Link copied to clipboard.')
    }, (err) => {
      showToast('Could not copy the track to your clipboard!')
    })
  }

  const onEdit = (beat) => {
    props.router.navigate(routes.dynamic(routes.producerBeatsEditPath, beat.id))
  }

  const onDelete = (beat, didHide) => {
    showAlertConfirm({
      title: 'Confirm Beat Deletion',
      message: 'Are you sure you want to delete your beat? It will no longer be available to others.',
      callback: () => {
        setLoading(true)
        axios.delete(routes.deleteBeatUrl, {
          data: {
            item_id: beat.id
          }
        }).then(() => {
          didHide(beat)
        }).catch((error) => {
          handleError(error)
        }).finally(() => {
          setLoading(false)
        })
      }
    })
  }

  const onTogglePlayPause = (beat) => {
    if (!beat) { return }
    if (isItemPlaying(beat.id)) {
      pauseItem(beat.id)
    } else {
      loadAndPlayBeat(beat)
    }
  }

  const onBoost = (beat) => props.router.navigate(routes.dynamic(routes.producerPurchaseBeatBoostPath, beat.id))

  if (loading) {
    return <div className='body-container'>
      <Layout>
        <Loading />
      </Layout>
    </div>
  }

  const content =
    <Layout>
      <Layout.Header>Beats</Layout.Header>
      <PaginatedList
        key={'beat'}
        loadUrl={routes.getBeatsPrivateUserUrl}
        cell={BeatCell}
        cellItemPropName={'beat'}
        cellAdditionalProps={{
          onShare: onShare,
          onDelete: onDelete,
          onBoost: onBoost,
          onEdit: onEdit,
          onPlayPause: (beat) => onTogglePlayPause(beat),
          isPlaying: (beat) => isItemPlaying(beat.id),
        }}
      />
    </Layout>

  return (
    <div className='body-container'>
      {content}
    </div>
  )
}

export default withRouter(ProducerBeats)
