import * as routes from '../../routes'
import { Navigation, NavigationButton } from '../../components'
import { LogoutButton } from '../../auth'

import beatsImg from '../../images/beats.svg'
import postsImg from '../../images/note.svg'
import playlistsImg from '../../images/playlists.svg'
import searchImg from '../../images/search.svg'
import moderationImg from '../../images/moderation.svg'
import discoverImg from '../../images/discover.svg'
import analyticsImg from '../../images/analytics.svg'
import boostImg from '../../images/boost.svg'

const AdminNavigation = ({router}) => <Navigation isAdmin={true}>
  <NavigationButton route={routes.adminSearchPath} image={searchImg} text='Search' />
  <NavigationButton route={routes.adminPostsPath} image={postsImg} text='Tracks' />
  <NavigationButton route={routes.adminBeatsPath} image={beatsImg} text='Beats' />
  <NavigationButton route={routes.adminPlaylistsPath} image={playlistsImg} text='Playlists' />

  <div className='app-navigation__divider' disabled />

  <NavigationButton route={routes.adminModerationPath} image={moderationImg} text='Moderation' />
  <NavigationButton route={routes.dynamic(routes.adminDiscoverPath, 'showcase')} image={discoverImg} text='Discover' />
  <NavigationButton route={routes.adminBoostListPath} image={boostImg} text='Boosts' />
  <NavigationButton route={routes.adminAnalyticsPath} image={analyticsImg} text='Analytics' />

  <div className='app-navigation__divider' disabled />

  <LogoutButton />
</Navigation>

export default AdminNavigation

