import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Input, Layout, AlertContext } from '../widgets'
import * as routes from '../routes'
import globals from '../globals'
import {
  AdminCommentCell,
  AdminBeatCell,
  FilterPicker,
  ItemReportCell,
} from './components'
import {
  withRouter,
  arrayDiff,
  Loading,
  keyScales,
  buyLicenseUrlFormInput,
  filenameFromSignedUrl,
} from '../shared'
import { NavigationTab, PaginatedList } from '../components'

const tabs = [
  'Edit',
  'Comments',
  'Reports',
]

const AdminEditBeat = (props) => {
  const [currentTab, setTab] = useState(tabs[0])
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(true)

  const [state, replaceState] = useState({
    beat: {},
    buyLicenseUrl: null,

    genre_id: null,
    bpm: null,
    key: null,

    blocks: [],
    existingBlockReasons: [],
    updatedBlockReasons: [],
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  useEffect(() => {
    load()
  }, [])

  const { showAlert, showAlertConfirm, handleError } = useContext(AlertContext)

  const beatId = () => {
    return props.router.params.beatId
  }

  const load = () => {
    setLoading(true)
    axios.get(
      routes.dynamic(routes.adminGetBeatUrl, beatId())
    ).then((response) => {
      const beat = response.data

      axios.get(
        routes.adminModerationBlockUrl, {
          params: {
            item_type: 'BEAT',
            item_id: beatId(),
          }
        }
      ).then((response) => {
        const blocks = response.data.data
        const reasons = blocks.map((block) => block.reason)

        setState({
          beat: beat,
          buyLicenseUrl: beat.buy_license_url,

          genre_id: beat.genre_id,
          bpm: parseInt(beat.bpm, 10),
          key: beat.key,

          blocks: blocks,
          existingBlockReasons: reasons,
          updatedBlockReasons: reasons,
        })
      }).catch((error) => {
        handleError(error)
      }).finally(() => {
        setLoading(false)
      })

    }).catch((error) => {
      handleError(error)
    })
  }

  const onFeature = () => {
    setLoading(true)
    axios.post(routes.adminBeatFeaturedUrl, {
      id: state.beat.id,
      featured: !state.beat.is_featured,
    }).then(load).catch(handleError)
  }

  const onSubmitImageBlock = () => {
    setLoading(true)
    axios.put(routes.adminModerationBlockImageUrl, {
      id: beatId(),
      type: 'BEAT',
      block: !state.beat.is_image_blocked,
    }).catch(handleError)
      .finally(load)
  }

  const onConvertToPost = () => {
    showAlertConfirm({ title: 'Convert to Post', message: 'Are you sure you want to convert this beat to a post?', callback: () => {
      setLoading(true)
      axios.post(routes.adminBeatConvertToPostUrl, {
        beat_id: beatId()
      }).catch(handleError)
        .finally(() => props.router.navigate(-1))
    }})
  }

  const onRecognize = () => {
    axios.post(routes.adminPostModerationRecognizeUrl, {
      item_type: 'BEAT',
      item_id: beatId(),
    }).catch(handleError)
      .finally(() => showAlert({ title: 'Running', message: 'Audio content recognition is now running, refresh this page in ~10 seconds to see the results.' }))
  }

  const parseBlocksDetail = (blocks) => {
    if (!blocks) {
      return null
    }
    return blocks.map(block => {
      try {
        const json = JSON.parse(block.details)
        if (json['acrid']) {
          return `
          Copyright Infringement
            acrid: ${json.acrid}
            Score: ${json.score}
            Label: ${json.label}
            Artists: ${json.artists.map(a => a.name).join(', ')}
            Album: ${json.album.name}
            Title: ${json.title}
            Release Date: ${json.release_date}
          `
        } else {
          return block.details
        }
      } catch (e) {
        return block.details
      }
    }).join('\n')
  }

  const handleChange = (key, value) => {
    setState({ [key]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setSubmitting(true)
    axios.patch(routes.dynamic(routes.adminPatchBeatUrl, beatId()),
      {
        genre: state.genre_id,
        bpm: state.bpm,
        key: state.key,
        buy_license_url: state.buyLicenseUrl,
      }
    ).then(() => {
      const toCreate = arrayDiff(state.updatedBlockReasons, state.existingBlockReasons)
      const toDelete = arrayDiff(state.existingBlockReasons, state.updatedBlockReasons)
      const createRequests = toCreate.map((reason) => {
        return axios.post(routes.adminModerationBlockUrl, {
          item_type: 'BEAT',
          item_id: beatId(),
          reason: reason,
        })
      })
      const deleteRequests = toDelete.map((reason) => {
        return axios.delete(routes.adminModerationBlockUrl, {
          data: {
            item_type: 'BEAT',
            item_id: beatId(),
            reason: reason,
          }
        })
      })
      Promise.all(createRequests.concat(deleteRequests)).catch(handleError).then(load)
    }).catch(handleError)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleBuyLicenseUrlChanged = (event) => {
    setState({ buyLicenseUrl: event.target.value })
  }

  if (loading) {
    return <Loading />
  }

  const blockDetail = parseBlocksDetail(state.blocks)

  return (
    <div className='body-container'>
      <Layout>
        <Layout.Header>
          {state.beat.beat_name}
        </Layout.Header>

        <div className='left-text'>
          {filenameFromSignedUrl(state.beat.audio_file_url)}
        </div>

        <AdminBeatCell beat={state.beat} noline>
          <AdminBeatCell.EditActions
            beat={state.beat}
            onFeature={onFeature}
            onBlockImage={onSubmitImageBlock}
            onConvertToPost={onConvertToPost}
            onRunAcrCloud={onRecognize}
          />
        </AdminBeatCell>

        <div className='navigation-tabs'>
          {tabs.map((tab, i) =>
            <NavigationTab
              key={i}
              text={tab}
              isActive={currentTab === tab}
              onClick={() => {
                if (currentTab !== tab) {
                  setTab(tab)
                }
              }}
            />
          )}
        </div>

        {currentTab === 'Edit' && <>
          <Form layout='vertical' onSubmit={handleSubmit}>
            <div className='submit-track'>
              <div className='submit-track__right'>
                <Form.Item label='Beat Name'>
                  <Input readOnly={true} disabled={true} type='text' value={state.beat.beat_name} />
                </Form.Item>
                <Form.Item label='Genre *'>
                  <select
                    required
                    translate={'no'}
                    defaultValue={state.beat.genre_id}
                    onChange={(event) => handleChange('genre_id', event.target.value)}
                    className='ant-input form-select'
                  >
                    {globals.genres.map((genre, index) => {
                      return <option key={index} className='select' value={genre.id}>{genre.value}</option>
                    })}
                  </select>
                </Form.Item>
                <Form.Item label='BPM'>
                  <Input
                    defaultValue={state.beat.bpm && parseInt(state.beat.bpm, 10)}
                    onChange={(event) => handleChange('bpm', parseInt(event.target.value, 10))}
                    type='number'
                    min='1'
                    max='300'
                    step='1'
                    placeholder='Auto Detect'
                  />
                </Form.Item>
                <Form.Item label='Key'>
                  <select
                    defaultValue={state.beat.key}
                    onChange={(event) => handleChange('key', event.target.value)}
                    placeholder={'Auto Detect'}
                  >
                    <option value=''>Auto Detect</option>
                    {keyScales.map((keyScale, index) => {
                      return <option key={index} className='select' value={keyScale.id}>{keyScale.value}</option>
                    })}
                  </select>
                </Form.Item>
                {buyLicenseUrlFormInput(handleBuyLicenseUrlChanged, state.beat.buy_license_url)}

                <FilterPicker.ItemBlock
                  onChange={(values) => handleChange('updatedBlockReasons', values)}
                  defaultValues={state.existingBlockReasons}
                />

                {blockDetail &&
                  <pre className='left-text font-12'>{blockDetail}</pre>
                }

                <Form.Button
                  htmlType='submit'
                  loading={submitting}
                >
                  Save
                </Form.Button>
              </div>
            </div>
          </Form>
        </>}

        {currentTab === 'Comments' && <PaginatedList
          key={'comments'}
          loadUrl={routes.commentListByItemUrl}
          loadParams={{
            'item_type': 'BEAT',
            'item_id': state.beat.id,
          }}
          cell={AdminCommentCell}
          cellItemPropName={'comment'}
        />}

        {currentTab === 'Reports' && <PaginatedList
          loadUrl={routes.adminModerationReportItemUrl}
          loadParams={{
            'item_type': 'BEAT',
            'item_id': state.beat.id,
          }}
          cell={ItemReportCell}
          cellItemPropName={'report'}
        />}
      </Layout>
    </div>
  )
}

export default withRouter(AdminEditBeat)
