import { useState } from 'react'
import NavigationTab from './NavigationTab'

const NavigationTabs = ({tabs}) => {
  const [currentTab, setTab] = useState(Object.keys(tabs)[0])

  return <div>
    <div className='navigation-tabs'>
      {Object.keys(tabs).map((tab, i) =>
        <NavigationTab
          key={i}
          text={tab}
          isActive={currentTab === tab}
          onClick={() => {
            if (currentTab !== tab) {
              setTab(tab)
            }
          }}
        />
      )}
    </div>

    {tabs[currentTab]}
  </div>
}

export default NavigationTabs
