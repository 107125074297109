import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import authClient from '../auth/authClient'
import { Layout, AlertContext } from '../widgets'
import * as routes from '../routes'
import {
  Loading,
  withRouter,
} from '../shared'
import { PlaylistCell } from '../components'

const ProducerPlaylists = (props) => {
  const [state, replaceState] = useState({
    loading: true,
    playlists: [],
    current_offset: 0,
    next_offset: 0
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  const { showAlertConfirm, showToast, handleError } = useContext(AlertContext)

  useEffect(() => {
    load([], state.current_offset)
  }, [])

  const load = (existingPlaylists, offset) => {
    axios.get(routes.dynamic(routes.playlistsForUserUrl, authClient.userId()), {
      params: {
        offset: offset,
      }
    }).then((response) => {
      setState({
        playlists: existingPlaylists.concat(response.data.data),
        current_offset: offset,
        next_offset: response.data.next_offset
      })
    }).catch((error) => {
      handleError(error)
    }).finally(() => {
      setState({ loading: false })
    })
  }

  const onShare = (playlist) => {
    navigator.clipboard.writeText(playlist.share_url).then(() => {
      showToast('Link copied to clipboard.')
    }, (err) => {
      showToast('Could not copy the link to your clipboard!')
    })
  }

  const onDelete = (playlist) => {
    showAlertConfirm({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this playlist? It will no longer be available to others.',
      callback: () => {
        setState({ loading: true })
        axios.delete(
          routes.dynamic(routes.playlistUrl, playlist.id)
        ).then((response) => {
          load([], 0)
        }).catch(
          handleError
        ).finally(() => {
          setState({ loading: false })
        })
      }
    })
  }

  if (state.loading) { return <Loading /> }

  const table =
    state.playlists.length <= 0 ?
      <div className='user-content-none'>
        You don't have any playlists yet - create some in the app!
      </div>
      :
      <div>
        <div className='layout-content'>
          {state.playlists.map((playlist, index) => <PlaylistCell playlist={playlist} key={index} onShare={onShare} onDelete={onDelete} />)}
        </div>
        {state.next_offset >= 0 &&
          <a href='#'
            onClick={e => {
              e.preventDefault()
              load(state.playlists, state.next_offset)
            }}
          >
            See More
          </a>
        }
      </div>

  const content =
    <Layout>
      <Layout.Header>Playlists</Layout.Header>
      {table}
    </Layout>

  return (
    <div className='body-container'>
      {content}
    </div>
  )
}

export default withRouter(ProducerPlaylists)
