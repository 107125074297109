import React from 'react'
import authClient from '../auth/authClient'

import closeImg from '../images/close.svg'
import burgerImg from '../images/burger.svg'
import volocoLogo from '../images/voloco_logo.svg'

class Navigation extends React.Component {
  state = {
    isOpen: false
  }

  closeNav = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const currentUser = authClient.currentUser()
    if (!currentUser) {
      return null
    }

    return <>
      <button
        className={`app-navigation__toggle ${this.state.isOpen ? 'is-open' : ''}`}
        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
      >
        <img className='close' src={closeImg} alt='Close' />
        <img className='burger' src={burgerImg} alt='More' />
      </button>
      <div
        className={`app-navigation__overlay ${this.state.isOpen ? 'is-open' : ''}`}
        onClick={this.closeNav}
      ></div>
      <div className='app-navigation__header mobile'>
        <div className='app-navigation__header-logo'>
          <img src={volocoLogo} alt='Voloco Logo' />
        </div>
      </div>
      <div className={`app-navigation ${this.state.isOpen ? 'is-open' : ''}`}>
        <div className='app-navigation__header'>
          <div className='app-navigation__header-logo'>
            <img src={volocoLogo} alt='Voloco Logo' />
          </div>
        </div>
        {!this.props.isAdmin && currentUser.profile &&
          <div className='app-navigation__profile'>
            <img src={currentUser.profile.profile_pics['200']} className='app-navigation__profile-img' />
            <div className='app-navigation__profile-username'>
              {currentUser.username}
            </div>
            <div className='app-navigation__profile-email'>
              {currentUser.profile.email}
            </div>
          </div>
        }

        {this.props.children.map((child, index) =>
          React.isValidElement(child) && child.props.disabled ? child : <div key={index} onClick={this.closeNav}>{child} </div>
        )}

        <div className='app-navigation__copyright'>
          {String.fromCharCode(169)} {new Date().getFullYear()} Resonant Cavity, LLC
        </div>
      </div>
    </>
  }
}

export default Navigation
