import { niceIso8601String } from '../../shared'

const ProducerProfileContent = (props) => {
  if (!props.profile) {
    return null
  }

  return <div className='layout-content'>
    <div className='producer-profile__container'>
      <div className='producer-profile__left'>
        <img src={props.profile.profile_pics['400']} alt='' className='producer-profile-pic' />
      </div>
      <div className='producer-profile__right'>
        <div className='producer-profile__view-content'>
          <div>
            <div className='producer-profile__view-title'>
              {props.username}
            </div>
            <div className='producer-profile__view-subtitle'>
              {props.profile.email}
              {props.is_banned &&
                <div>
                  Banned {props.banned_until == null ? 'indefinitely' : `until ${niceIso8601String(props.banned_until)}`} {props.audience === 'admin' && `by ${props.banned_by.name} on ${niceIso8601String(props.banned_at)}`}
                </div>
              }
            </div>
            <div className='producer-profile__view-bio'>
              <pre><p>{props.profile.bio}</p></pre>
            </div>
          </div>
            {props.profile.social_links.some((socialLink) => socialLink.url) && <div className='producer-profile__social'>
              {props.profile.social_links.map((socialLink, index) => {
                return socialLink.url && <a key={index} href={socialLink.url} target='_blank' rel='noreferrer noopener'>
                  {socialLink.service_name}
                </a>
              })}
            </div>}
        </div>
      </div>
    </div>
  </div>
}

const Row = ({title, value}) => <tr><th className='width-200px color-text-light'>{title}</th><td>{value}</td></tr>

ProducerProfileContent.AdminDetails = (props) => <table className='left-text vfont-body-big'>
 <tbody>
    <Row title='created' value={niceIso8601String(props.created_at)} />
    <Row title='uuid' value={props.uuid} />
    {props.authentication_identities?.map((identity, index) => <Row key={index} title={identity.type.toLowerCase()} value={identity.external_id} />)}
    <Row title='country' value={props.location?.country} />
    <Row title='country region' value={props.location?.country_region} />
    <Row title='premium' value={props.details?.premium.toString()} />
    <Row title='beats' value={props.details['beat_count']} />
    <Row title='tracks' value={props.details['top_track_count']} />
    <Row title='playlists' value={props.details['playlist_count']} />
    <Row title='plays' value={props.details['total_plays']} />
    <Row title='followers' value={props.details['follower_count']} />
    <Row title='following' value={props.details['follow_count']} />
    <Row title='favorites' value={props.details['like_count']} />
    <Row title='beatstars managed' value={props.beatstars_managed.toString()} />
    <Row title='beatstars sync enabled' value={props.beatstars_sync_enabled.toString()} />
  </tbody>
</table>

export default ProducerProfileContent
