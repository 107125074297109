import React, { Component } from 'react'
import { Button, Layout } from '../../widgets'

export default class OrderableItems extends Component {

  // prop requirements:

  // state.items: object for ids -> items
  // state.activeIds: array of active ids
  // state.inactiveIds: array of inactive ids
  // state.saving: bool whether saving is occurring

  // setState: function to set state
  // getItemId: function to get an item's id
  // itemRow: function to render an item row
  // save: function to save changes
  // newItem: function to perform a new item action
  // showDeleteButton: function to determine whether the delete button should be shown
  // delete: function to perform a delete of an item

  moveUp = (id) => {
    const index = this.props.state.activeIds.indexOf(id)
    const activeIds = [...this.props.state.activeIds]

    const swappedId = activeIds[index-1]
    activeIds[index-1] = id
    activeIds[index] = swappedId

    this.props.setState({ activeIds })
  }

  moveDown = (id) => {
    const index = this.props.state.activeIds.indexOf(id)
    const activeIds = [...this.props.state.activeIds]

    const swappedId = activeIds[index+1]
    activeIds[index+1] = id
    activeIds[index] = swappedId

    this.props.setState({ activeIds })
  }

  activate = (id) => {
    const index = this.props.state.inactiveIds.indexOf(id)
    const activeIds = [...this.props.state.activeIds]
    const inactiveIds = [...this.props.state.inactiveIds]

    activeIds.push(id)
    inactiveIds.splice(index, 1)

    this.props.setState({ activeIds, inactiveIds })
  }

  deactivate = (id) => {
    const index = this.props.state.activeIds.indexOf(id)
    const activeIds = [...this.props.state.activeIds]
    const inactiveIds = [...this.props.state.inactiveIds]

    activeIds.splice(index, 1)
    inactiveIds.push(id)

    this.props.setState({ activeIds, inactiveIds })
  }

  activeRow = (item, index) => {
    return this.props.itemRow(item, index,
      <div className='orderable-items-move'>
        {index > 0 &&
          <button onClick={() => this.moveUp(this.props.getItemId(item))}>
            ⬆
          </button>
        }
        {index < this.props.state.activeIds.length - 1 &&
          <button onClick={() => this.moveDown(this.props.getItemId(item))}>
            ⬇
          </button>
        }
        <button onClick={() => this.deactivate(this.props.getItemId(item))}>
          Deactivate
        </button>
      </div>
    )
  }

  inactiveRow = (item, index) => {
    return this.props.itemRow(item, index,
      <div className='orderable-items-move'>
        <button onClick={() => this.activate(this.props.getItemId(item))}>
          Activate
        </button>
        {this.props.showDeleteButton(item) &&
          <button onClick={() => this.props.delete(item)}>
            Delete
          </button>
        }
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className='flex-row'>
          <Button
            onClick={() => this.props.save()}
            loading={this.props.state.saving}
          >
            Save
          </Button>
          <Button onClick={() => this.props.newItem()}>
            New
          </Button>
        </div>
        <Layout.Header>Active</Layout.Header>
        {this.props.state.activeIds.length ? this.props.state.activeIds.map((id, index) => this.activeRow(this.props.state.items[id], index)) : 'No active content'}
        <Layout.Header>Inactive</Layout.Header>
        {this.props.state.inactiveIds.length ? this.props.state.inactiveIds.map((id, index) => this.inactiveRow(this.props.state.items[id], index)) : 'No inactive content'}
      </div>
    )
  }
}
