import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'
import * as routes from '../routes'
import { Menu } from '../widgets'
import ProducerProfileEdit from './ProducerProfileEdit'
import {
  Loading,
} from '../shared'
import volocoLogo from '../images/voloco_logo.svg'
import authClient from '../auth/authClient'

const ProducerSignUp = (props) => {
  const [loading, setLoading] = useState(false)
  const [socialLinkTemplates, setSocialLinkTemplates] = useState(null)

  if (!authClient.isExternallyAuthenticated() || authClient.isLoggedIn()) {
    return <Navigate to={routes.producerRootPath} />
  }

  if (loading) {
    return <Loading/>
  }

  if (socialLinkTemplates == null) {
    setLoading(true)
    axios.get(routes.userSocialLinkTemplatesUrl)
      .then((response) => {
        setSocialLinkTemplates(response.data)
      }).catch((error) => {
        setSocialLinkTemplates([])
      }).finally(() => {
        setLoading(false)
      })
  }

  return <div>
    <Menu className='login-menu'>
      <Menu.Item key='logo' disabled>
        <a href='https://resonantcavity.com'>
          <img
            className='voloco-logo'
            src={volocoLogo}
            alt='Voloco Logo'
          />
        </a>
      </Menu.Item>
    </Menu>
    <ProducerProfileEdit {...props} {...{ header: 'Complete Your Profile to Continue', isSigningUp: true, socialLinkTemplates: socialLinkTemplates }} />
  </div>
}

export default ProducerSignUp
