import React from 'react'
import { Dropdown, Tooltip } from '../widgets'
import classNames from '../widgets/util'

import noteImg from '../images/note.svg'
import cartImg from '../images/cart.svg'
import effectImg from '../images/effect.svg'
import likeImg from '../images/like.svg'
import playImg from '../images/play.svg'
import useImg from '../images/use.svg'
import commentImg from '../images/comment.svg'
import overflowImg from '../images/overflow.svg'

const Cell = ({noline, children, cellClassName, onClick}) =>
  <div className={classNames('cell-container', noline && 'noline', onClick && 'clickable-cell')} onClick={onClick}>
    <div className={classNames('cell', cellClassName && cellClassName)}>
      {children}
    </div>
  </div>

Cell.Image = ({src, alt, large, className}) => {
  const baseClassName = large ? 'cell__image large' : 'cell__image'
  const finalClassName = className ? `${baseClassName} ${className}` : baseClassName
  return src ?
    <img className={finalClassName} src={src} alt={alt} /> :
    <img className={`${finalClassName} fallback`} src={noteImg} alt={alt} />
}

Cell.Body = (props) =>
  <div className='cell__body'>
    {props.children}
  </div>

Cell.Line = (props) =>
  <div className='cell__line'>
    {props.children}
  </div>

Cell.LineTitle = (props) =>
  <div className='cell__line__title'>
    {props.children}
  </div>

Cell.LineNotice = (props) =>
  <div className='cell__line__notice'>
    {props.children}
  </div>

Cell.LineImportant = (props) =>
  <div className='cell__line__important'>
    {props.children}
  </div>

Cell.LineFeatured = (props) =>
  <Tooltip title={'This content is featured!'}>
    <div className='cell__line__featured'>
      {props.children}
    </div>
  </Tooltip>

Cell.UseCount = (props) =>
  <Cell.Annotated image={useImg}>
    {props.children}
  </Cell.Annotated>

Cell.PlayCount = (props) =>
  <Cell.Annotated image={playImg}>
    {props.children}
  </Cell.Annotated>

Cell.LikeCount = (props) =>
  <Cell.Annotated image={likeImg}>
    {props.children}
  </Cell.Annotated>

Cell.CommentCount = (props) =>
  <Cell.Annotated image={commentImg}>
    {props.children}
</Cell.Annotated>

Cell.ItemCount = (props) =>
  <Cell.Annotated image={noteImg}>
    {props.children}
  </Cell.Annotated>

Cell.AudioEffect = (props) =>
  <Cell.Annotated image={effectImg}>
    {props.children}
  </Cell.Annotated>

Cell.MusicNote = (props) =>
  <Cell.Annotated image={noteImg}>
    {props.children}
  </Cell.Annotated>

Cell.BuyLicense = (props) =>
  <Cell.Annotated image={cartImg}>
    {props.children}
  </Cell.Annotated>

Cell.Annotated = ({image, large, children}) =>
  <div className='cell__annotated'>
    <img className={`cell__annotated__image ${large ? 'large' : ''}`} src={image} alt='play' draggable='false'/>
    {children}
  </div>

Cell.OverflowButton = ({actions}) => {
  const anchor = <img className='cell__overflowButton' src={overflowImg} alt='overflow' draggable='false'/>
  return <Dropdown anchor={anchor} actions={actions} />
}

Cell.Actions = (props) =>
  <div className='cell__actions'>
    {props.children}
  </div>

Cell.Button = (props) =>
  <button
    className={'cell__button'}
    onClick={props.onClick}
    type='button'
  >
    {props.children}
  </button>

export default Cell
