import * as routes from '../../routes'
import { AdminBoostCell } from '../components'
import { NavigationTabs, PaginatedList } from '../../components'
import {
  withRouter,
} from '../../shared'

const AdminUserBoosts = (props) => {
  const getUserId = () => {
    return props.router.params.userId
  }

  return <NavigationTabs
    tabs={{
      'Ended': <PaginatedList
        key={'ended'}
        loadUrl={routes.adminBoostUserUrl}
        loadParams={{
          'user_id': getUserId(),
          'type': 'ended'
        }}
        cell={AdminBoostCell}
        cellItemPropName={'boost'}
      />,
      'Active': <PaginatedList
        key={'active'}
        loadUrl={routes.adminBoostUserUrl}
        loadParams={{
          'user_id': getUserId(),
          'type': 'active'
        }}
        cell={AdminBoostCell}
        cellItemPropName={'boost'}
      />,
    }}
  />
}

export default withRouter(AdminUserBoosts)
