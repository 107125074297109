import React from 'react'
import UserLink from './UserLink'
import { formatStat, niceIso8601String } from '../../shared'
import { Cell } from '../../components'

const AdminUserCell = ({user, actions, children}) =>
  <Cell>
    <Cell.Image src={user.profile.profile_pics['200']} alt='' />
    <Cell.Body>
      <Cell.Line>
        <Cell.LineTitle>
          <UserLink user_id={user.user_id} username={user.username} />
        </Cell.LineTitle>
        {user.location?.country && <span>({user.location?.country})</span>}
      </Cell.Line>

      {user.details && <>
        <Cell.Line>
          <div>{`${formatStat(user.details.beat_count)} beats • ${formatStat(user.details.beat_uses)} plays`}</div>
        </Cell.Line>
        <Cell.Line>
          <div>{`${formatStat(user.details.top_track_count)} tracks • ${formatStat(user.details.top_track_plays)} plays`}</div>
        </Cell.Line>
      </>}

      {user.is_banned && user.banned_by && <>
        <Cell.Line>
          Banned {user.banned_until == null ? 'indefinitely' : `until ${niceIso8601String(user.banned_until)}`} by {user.banned_by.name} on {niceIso8601String(user.banned_at)}
        </Cell.Line>
      </>}
    </Cell.Body>

    {actions && <Cell.OverflowButton actions={actions} />}

    {children}
  </Cell>

AdminUserCell.ModerationActions = ({pendingCount, reviewedCount, onDetails, onAllow, onBan}) =>
  <Cell.Actions>
    <Cell.Button onClick={onDetails}>
      {pendingCount} / {pendingCount+reviewedCount}
    </Cell.Button>
    <Cell.Button onClick={onAllow}>
      Allow
    </Cell.Button>
    <Cell.Button onClick={onBan}>
      Profile
    </Cell.Button>
  </Cell.Actions>

export default AdminUserCell
