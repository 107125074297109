import { useState } from 'react'
import * as Sentry from '@sentry/react'
import * as routes from '../../routes'
import PaginatedList from '../../components/PaginatedList'
import FilterPicker from './FilterPicker'
import UserLink from './UserLink'
import AdminEditItemLink from './AdminEditItemLink'

const ItemBlockAuditTable = (props) => {
  const [state, replaceState] = useState({
    loading: false,

    // filtering
    itemType: 'BEAT',
    blockReason: 'COPYRIGHT',
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  const dataRow = (title, data) => {
    var dataArray = data
    if (!Array.isArray(data)) {
      dataArray = Array.of(data)
    }
    return <div className='copyright-audit-row'>
      <div className='copyright-audit-row-header'>
        <div>{title}</div>
        {Object.keys(dataArray[0]).map((key, index) =>
          <div key={index}>{key}</div>
        )}
      </div>

      {dataArray.map((d, index) =>
        <div className='copyright-audit-row-body' key={index}>
          <div />
          {Object.keys(d).map((key, index) => <div key={`${index}-${key}`}>{d[key]}</div>)}
        </div>
      )}
    </div>
  }

  const ItemBlockAuditCell = ({item, blockReason}) => {
    const rows = []

    switch (blockReason) {
      case 'COPYRIGHT':
        const rezcavAudioData = {
          Audio: <a target='_blank' rel='noopener noreferrer' href={item.audio_url}>Listen</a>,
          Username: <UserLink user_id={item.user_id} username={item.username} />,
          Title: <AdminEditItemLink item_name={item['name']} item_type={item['type']} item_id={item['id']} />,
        }
        rows.push(dataRow('Rezcav', rezcavAudioData))

        try {
          const json = JSON.parse(item.item_block.details)
          if (json['acrid']) {
            const acrcloudMusic = {
              Label: json.label,
              Artist: json.artists.map(x => x.name).join(', '),
              Title: json.title,
              Score: json.score,
            }
            rows.push(dataRow('ACRCloud', acrcloudMusic))
          } else {
            rows.push(dataRow('ACRCloud', { 'N/A': '' }))
          }
        } catch (e) {
          rows.push(dataRow('ACRCloud', { 'N/A': '' }))
        }
        break
      case 'USER_BAN':
      case 'REPORTS':
        const rezcavData = {
          Image: <a target='_blank' rel='noopener noreferrer' href={item.image_url}>View</a>,
          Audio: <a target='_blank' rel='noopener noreferrer' href={item.audio_url}>Listen</a>,
          Username: <UserLink user_id={item.user_id} username={item.username} />,
          Title: <AdminEditItemLink item_name={item['name']} item_type={item['type']} item_id={item['id']} />,
        }
        rows.push(dataRow('Rezcav', rezcavData))
        break
      default:
        Sentry.captureMessage(`Unknown tab type: ${props.recognizeType}`)
    }

    return <div className='copyright-audit-element' key={item.id}>
      {rows}
    </div>
  }

  const itemTypeFilter = <FilterPicker.ItemType
    onChange={(values) => setState({ itemType: values[0] })}
    defaultValues={[state.itemType]}
    singleChoice={true}
  />

  const blockReasonFilter = <FilterPicker.ItemBlock
    onChange={(values) => setState({ blockReason: values[0] })}
    defaultValues={[state.blockReason]}
    singleChoice={true}
  />

  return (
    <>
    <div className='flex-row align-stretch copyright-audit-filters'>
      {itemTypeFilter}
      {blockReasonFilter}
    </div>
    <PaginatedList
      key={`itemBlocks-${state.itemType}-${state.blockReason}`}
      loadUrl={routes.adminModerationRecognizeAuditUrl}
      loadParams={{
        item_type: state.itemType,
        block_reason: state.blockReason,
      }}
      cell={ItemBlockAuditCell}
      cellItemPropName={'item'}
      cellAdditionalProps={{ blockReason: state.blockReason }}
    />
    </>
  )
}

export default ItemBlockAuditTable
