import { useState, useRef, useEffect } from 'react'
import classNames from './util'

const Dropdown = (props) => {
  const { ref, isVisible, setIsVisible } = useComponentVisible(false)
  const { anchor, actions } = props
  return <div ref={ref} className={classNames('widgets-Dropdown', props.className)} onClick={() => setIsVisible(!isVisible)}>
    <div className='widgets-Dropdown-Anchor'>{anchor}</div>
    <div className={classNames('widgets-Dropdown-Actions', isVisible && 'show')}>
      {actions.map((action, index) =>
        action.contentOverride ? action.contentOverride :
        <div className='widgets-Dropdown-Action' key={index} onClick={action.onClick}>
          {action.title}
        </div>
      )}
    </div>
  </div>
}

const useComponentVisible = (initial) => {
  const [isVisible, setIsVisible] = useState(initial)
  const ref = useRef(null)
  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [])
  return { ref, isVisible, setIsVisible }
}

export default Dropdown
