import React, { createContext, useState, useEffect } from 'react'

export const FeatureFlagContext = createContext('feature-flags-context')

export const FeatureFlagContextProvider = ({ children }) => {
  const defaultFlags = [
    { name: 'showDMs', value: false }
  ]

  const [flags, setFlags] = useState(defaultFlags)

  useEffect(() => {
    if (window?.localStorage) {
      const storedFlags = defaultFlags.map(flag => ({
        ...flag,
        value: JSON.parse(window.localStorage.getItem(flag.name)) || flag.value
      }))
      setFlags(storedFlags)
    }
  }, [])

  useEffect(() => {
    if (window?.localStorage) {
      flags.forEach(flag => {
        window.localStorage.setItem(flag.name, JSON.stringify(flag.value))
      })
    }
  }, [flags])

  const getFlagValue = (name) => flags.find(flag => flag.name === name)?.value || false

  const setFlagValue = (name, value) => {
    setFlags(flags.map(flag =>
      flag.name === name ? { ...flag, value } : flag
    ))
  }

  return <FeatureFlagContext.Provider
    value={{ flags, getFlagValue, setFlagValue }}>
      {children}
  </FeatureFlagContext.Provider>

}
