import React, { Component } from 'react'

import cloudImg from '../images/cloud.svg'

class FilePickerOverlay extends Component {
  render() {
    return (
      <div
        className={`producer-file-upload ${(this.props.image || this.props.audioFile) && 'has-image'} ${this.props.readOnly && 'readOnly'}`}
        style={{ backgroundImage: `url(${this.props.image})` }}
      >
        {this.props.audioFile &&
          <div className='producer-file-upload__audio-file-name'>
            {this.props.audioFile}
          </div>
        }

        {(this.props.image || this.props.audioFile) &&
          <div className='overlay'></div>
        }

        <label htmlFor={this.props.inputName} className={this.props.readOnly && 'readOnly'}>
          <img src={cloudImg} alt='Upload File' />
          <div className='producer-file-upload__title'>{this.props.title}</div>
          {this.props.description &&
            <div
              className='producer-file-upload__description'
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            ></div>
          }
        </label>

        {this.props.children}
      </div>
    )
  }
}

export default FilePickerOverlay
