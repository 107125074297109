const getEnvironment = () => {
  if (/http(s)?:\/\/(api|voloco|beats).resonantcavity.com/.test(window.location.origin)) {
    return 'prod'
  } else if (/http(s)?:\/\/qa.resonantcavity.com/.test(window.location.origin)) {
    return 'qa'
  } else {
    return 'dev'
  }
}

export default getEnvironment
