import { useState } from 'react'
import { Layout } from '../widgets'
import * as routes from '../routes'
import { ProducerActiveBoostCell, ProducerEndedBoostCell } from './components'
import { NavigationTab, PaginatedList } from '../components'

const tabs = {
  'Active': <PaginatedList
    key={'active'}
    loadUrl={routes.producerBoostListUrl}
    loadParams={{'type': 'active'}}
    cell={ProducerActiveBoostCell}
    cellItemPropName={'boost'}
  />,
  'Ended': <PaginatedList
    key={'ended'}
    loadUrl={routes.producerBoostListUrl}
    loadParams={{'type': 'ended'}}
    cell={ProducerEndedBoostCell}
    cellItemPropName={'boost'}
  />,
}

const ProducerBoosts = (props) => {

  const [currentTab, setTab] = useState(Object.keys(tabs)[0])

  return (
    <div className='body-container'>
      <Layout>
        <Layout.Header>Boosts</Layout.Header>

          <div>
            <div className='navigation-tabs'>
              {Object.keys(tabs).map((tab, i) =>
                <NavigationTab
                  key={i}
                  text={tab}
                  isActive={currentTab === tab}
                  onClick={() => {
                    if (currentTab !== tab) {
                      setTab(tab)
                    }
                  }}
                />
              )}
            </div>

            {tabs[currentTab]}
          </div>

      </Layout>
    </div>
  )
}

export default ProducerBoosts
