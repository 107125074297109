import { useContext } from 'react'
import { FeatureFlagContext } from '../widgets/FeatureFlagContext'
import { Layout } from '../widgets'

const AdminFeatureFlagsControl = (props) => {
  const { flags, setFlagValue} = useContext(FeatureFlagContext)

  const handleFeatureToggle = (flag) => {
    setFlagValue(flag.name, !flag.value)
  }

  return <div className='body-container'>
    <Layout>
      <Layout.Header>
        Update Feature Flags
      </Layout.Header>
      <div className='moderator-content'>
        {flags.map((flag, index) => {
          return <div key={index}><p>{flag.name}</p><button onClick={() => handleFeatureToggle(flag)}>{flag.value ? 'On' : 'Off'}</button></div>
        })}
      </div>
    </Layout>
  </div>
}

export default AdminFeatureFlagsControl
