import React from 'react'
import * as moment from 'moment'
import { Button } from '../../widgets'

const ImageBlockButton = ({onSave, isBlocked, blockedMessage, blockMessage}) => {
  const disabledDate = (current) => {
    // Cannot select days before today and today
    return current && current < moment().endOf('day')
  }

  var actions = null
  if (isBlocked) {
    actions = <>
      <Button
        className='user-ban-input--button unblock-img'
        onClick={onSave}
      >
        <span>{blockedMessage}</span>
      </Button>
    </>
  } else {
    actions = <>
      <Button
        className='user-ban-input--button'
        onClick={onSave}
      >
        <span>{blockMessage}</span>
      </Button>
    </>
  }

  return (
    <div className='user-ban-input'>
      {actions}
    </div>
  )
}

ImageBlockButton.Profile = ({onSave, isBlocked}) => <ImageBlockButton
  onSave={onSave}
  isBlocked={isBlocked}
  blockedMessage={'Profile Image Blocked'}
  blockMessage={'Block Profile Image'}
/>

ImageBlockButton.Item = ({onSave, isBlocked}) => <ImageBlockButton
  onSave={onSave}
  isBlocked={isBlocked}
  blockedMessage={'Image Blocked'}
  blockMessage={'Block Image'}
/>

export default ImageBlockButton
