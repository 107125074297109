import { useCallback, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { debounce } from 'lodash'
import { Input, AlertContext } from '../widgets'
import * as routes from '../routes'
import Cell from '../components/Cell'
import { formatStat } from '../shared'

const UserSearch = (props) => {
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState(null)
  const [users, setUsers] = useState([])

  const { handleError } = useContext(AlertContext)

  const search = (query) => {
    setLoading(true)
    if (query?.length === 0) {
      setLoading(false)
      setUsers([])
      return
    }
    const body = {
      query,
    }

    axios.post(routes.searchUsersUrl, body).then((response) => {
      setUsers(response.data.data)
    }).catch((error) => {
      handleError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  const onClickUser = props.onClickUser

  const debouncedSearch = useCallback(debounce(search, 500), [])

  useEffect(() => {
    debouncedSearch(query)
  }, [query])

  let content = <div className='centered-loading'><div className='loading'></div></div>
  if (!loading) {
    content =
      <div className='search-no-results'>
        No results found.
      </div>

    if (users.length > 0) {
      content =
        <div className='beats-search-results'>
          {users.map((user, i) => UserCell({user, onClickUser}))}
        </div>

    }
  }

  return (
    <>
      <Input
        className='search-input'
        type='text'
        placeholder='Search for User'
        onChange={(event) => setQuery(event.target.value)}
      />
      <br />
    <div className='search-container'>
        {content}
    </div>
    </>
  )
}

const UserCell = ({user, onClickUser}) => {
  return <Cell noline={true} onClick={() => onClickUser(user.user_id)}>
    <Cell.Image src={user.profile.profile_pics['200']} alt='profile picture' className='user-profile-image'/>
    <Cell.Body>
      <Cell.Line>
        <Cell.LineTitle>{user.username}</Cell.LineTitle>
      </Cell.Line>
      {user.details && <>
        {user.details.beat_count > 0 && <Cell.Line>
          <div>{`${formatStat(user.details.beat_count)} beats • ${formatStat(user.details.beat_uses)} plays`}</div>
        </Cell.Line>}
        {user.details.top_track_count > 0 && <Cell.Line>
          <div>{`${formatStat(user.details.top_track_count)} tracks • ${formatStat(user.details.top_track_plays)} plays`}</div>
        </Cell.Line>}
      </>}
    </Cell.Body>
  </Cell>
}

export default UserSearch
