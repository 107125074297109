import React from 'react'
import pauseImg from '../images/player-pause.svg'
import playImg from '../images/player-play.svg'
import {
  niceIso8601String,
  formattedDuration,
  formatStat,
  niceBlockedMessage,
} from '../shared'
import PublicUserLink from './PublicUserLink'
import PublicItemLink from './PublicItemLink'
import Cell from './Cell'

const PostCell = ({audience, post, onShare, onDelete, onPlayPause, isPlaying, didHide}) => {
  const actions = []
  if (onDelete) { actions.unshift({ title: 'Delete', onClick: () => { onDelete(post, didHide) } }) }
  if (!post.is_blocked) {
    if (onShare) { actions.unshift({ title: 'Copy Link', onClick: () => { onShare(post) } }) }
  }

  return <Cell>
    <div className='position-relative'>
      {isPlaying && onPlayPause && <span className='play-pause-overlay' onClick={() => onPlayPause(post)}>
        <img src={isPlaying(post) ? pauseImg : playImg} alt='play' />
      </span>}
      <Cell.Image src={post.signed_image_urls['100']} alt='' />
    </div>

    <Cell.Body>
      <Cell.Line>
        {audience === 'public' ?
          <PublicItemLink name={post.title} type={'POST'} id={post.id} /> :
          <Cell.LineTitle>{post.title}</Cell.LineTitle>
        }
        {post.is_blocked && <Cell.LineImportant>{niceBlockedMessage(post.is_blocked)}</Cell.LineImportant>}
        {!post.is_blocked && post.is_featured && <Cell.LineFeatured />}
      </Cell.Line>

      <Cell.Line>
        {audience === 'public' ?
          <PublicUserLink small user_id={post.user_id} username={post.user_name} /> :
          <div>{niceIso8601String(post.created_at)}</div>
        }
        <div>•</div>
        <div>{formattedDuration(post.duration_seconds)}</div>
      </Cell.Line>

      <Cell.Line>
        {<Cell.PlayCount>{formatStat(post.play_count)}</Cell.PlayCount>}
        {<Cell.LikeCount>{formatStat(post.like_count)}</Cell.LikeCount>}
        {<Cell.CommentCount>{formatStat(post.comment_count)}</Cell.CommentCount>}
      </Cell.Line>

      <Cell.Line>
        {post?.beat?.beat_name &&
          <Cell.MusicNote>{post?.beat?.beat_name}</Cell.MusicNote>
        }
      </Cell.Line>
    </Cell.Body>
    {actions.length > 0 && <Cell.OverflowButton actions={actions} />}
  </Cell>
}

export default PostCell
