import React from 'react'
import {
  niceIso8601String,
  formattedDuration,
  niceBlockedMessage,
  formatStat,
} from '../../shared'
import AdminEditItemLink from './AdminEditItemLink'
import AdminPostListen from './AdminPostListen'
import UserLink from './UserLink'
import { Cell } from '../../components'

const AdminPostCell = ({post, noline, children}) => {
  return (
    <Cell noline={noline}>
      <Cell.Image src={post.signed_image_urls['400']} alt='' large />
      <Cell.Body>
        <Cell.Line>
          <Cell.LineTitle>
            <AdminEditItemLink item_name={post.title} item_type={'POST'} item_id={post.id} />
          </Cell.LineTitle>
          {post.is_blocked && <Cell.LineImportant>{niceBlockedMessage(post.is_blocked)}</Cell.LineImportant>}
          {!post.is_blocked && post.is_featured && <Cell.LineFeatured />}
        </Cell.Line>

        <Cell.Line>
          <UserLink user_id={post.user_id} username={post.user_name} />
          {post.user_country && <span>({post.user_country}{post.source === 'amn' ? ' - Local' : ''})</span>}
        </Cell.Line>

        <Cell.Line>
          <div>{formattedDuration(post.duration_seconds)}</div>
          <div>{niceIso8601String(post.created_at)}</div>
        </Cell.Line>

        {<Cell.Line>
          {<Cell.PlayCount>{formatStat(post.play_count)}</Cell.PlayCount>}
          {<Cell.LikeCount>{formatStat(post.like_count)}</Cell.LikeCount>}
          {<Cell.CommentCount>{formatStat(post.comment_count)}</Cell.CommentCount>}
        </Cell.Line>}

        {(post.genre || post.effect) && <Cell.Line>
          {post.genre && <Cell.MusicNote>{post.genre}</Cell.MusicNote>}
          {post.effect && <Cell.AudioEffect>{post.effect}</Cell.AudioEffect>}
        </Cell.Line>}

        <Cell.Line>
          {post?.beat?.beat_name &&
            <Cell.MusicNote>{post?.beat?.beat_name}</Cell.MusicNote>
          }
        </Cell.Line>

        <Cell.Line>
          <AdminPostListen post={post} />
        </Cell.Line>
      </Cell.Body>

      {children}
    </Cell>
  )
}

AdminPostCell.ModerationActions = ({pendingCount, reviewedCount, onDetails, onAllow, onBlock}) =>
  <Cell.Actions>
    <Cell.Button onClick={onDetails}>
      {pendingCount} / {pendingCount+reviewedCount}
    </Cell.Button>
    <Cell.Button onClick={onAllow}>
      Allow
    </Cell.Button>
    <Cell.Button onClick={onBlock}>
      Block
    </Cell.Button>
  </Cell.Actions>

AdminPostCell.FeatureActions = ({post, onFeatureClick}) => {
  var actions = null
  if (post.is_featured) {
    actions = onFeatureClick && <Cell.Button onClick={() => onFeatureClick(post)}>Unfeature</Cell.Button>
  } else {
    actions = <>
      {onFeatureClick && <Cell.Button onClick={() => onFeatureClick(post)}>Feature</Cell.Button>}
    </>
  }
  return <Cell.Actions>
    {actions}
  </Cell.Actions>
}

AdminPostCell.EditActions = ({post, onFeature, onBlockImage, onConvertToBeat, onRunAcrCloud}) => <Cell.Actions>
  {onFeature && <Cell.Button onClick={onFeature}>{post.is_featured ? 'Unfeature' : 'Feature'}</Cell.Button>}
  {onBlockImage && <Cell.Button onClick={onBlockImage}>{post.is_image_blocked ? 'Unblock Image' : 'Block Image'}</Cell.Button>}
  {onConvertToBeat && <Cell.Button onClick={onConvertToBeat}>Convert to Beat</Cell.Button>}
  {onRunAcrCloud && <Cell.Button onClick={onRunAcrCloud}>Run ACR Cloud</Cell.Button>}
</Cell.Actions>

export default AdminPostCell
