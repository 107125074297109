import { useContext, useState } from 'react'
import * as moment from 'moment'
import axios from 'axios'
import { Layout, DateRangePicker, AlertContext } from '../../widgets'
import * as routes from '../../routes'
import { AdminBoostCell } from '../components'
import { Cell, NavigationTab, PaginatedList } from '../../components'
import { formatStat } from '../../shared'

const tabs = {
  'Ended': <PaginatedList
    key={'ended'}
    loadUrl={routes.adminBoostListUrl}
    loadParams={{'type': 'ended'}}
    cell={AdminBoostCell}
    cellItemPropName={'boost'}
  />,
  'Active': <PaginatedList
    key={'active'}
    loadUrl={routes.adminBoostListUrl}
    loadParams={{'type': 'active'}}
    cell={AdminBoostCell}
    cellItemPropName={'boost'}
  />
}

const AdminBoosts = (props) => {

  const { handleError } = useContext(AlertContext)

  const [currentTab, setTab] = useState(Object.keys(tabs)[0])
  const [stats, setStats] = useState(null)

  const [since, setSince] = useState(moment().subtract(31, 'days').utc())
  const [until, setUntil] = useState(moment().utc())

  if (stats == null) {
    axios.get(routes.adminBoostStatsUrl, {
      params: {
        since: since.format('YYYY-MM-DD'),
        until: until.format('YYYY-MM-DD')
      }
    }).then((response) => {
      setStats(response.data)
    }).catch((error) => {
      handleError(error)
    })
  }

  const onDateChanged = (key, date) => {
    const day = moment(date, 'YYYY-MM-DD').utc()
    key === 'startDate' ? setSince(day) : setUntil(day)
  }

  return (
    <div className='body-container'>
      <Layout>
        <Layout.Header>Boosts</Layout.Header>

          <>
            <h2 className='left-text'>Stats</h2>
            <DateRangePicker
              minDate={'2023-06-20'}
              startDate={since}
              endDate={until}
              onDateChanged={onDateChanged}
              onGo={() => setStats(null)}
            />
            <div className='flex-row align-stretch'>
              <div>
                <h4 className='left-text'>Average Engagement</h4>
                {stats &&
                  <Cell.Body>
                    {stats.averages.map((average, i) =>
                      <Cell.Line key={i}>
                        {average.item_type === 'BEAT' ? 'Beats' : 'Tracks'}
                        {<Cell.PlayCount>{formatStat(average.plays)}</Cell.PlayCount>}
                        {<Cell.LikeCount>{formatStat(average.likes)}</Cell.LikeCount>}
                        {<Cell.CommentCount>{formatStat(average.comments)}</Cell.CommentCount>}
                        {average.item_type === 'BEAT' && <Cell.UseCount>{formatStat(average.uses)}</Cell.UseCount>}
                      </Cell.Line>
                    )}
                  </Cell.Body>
                }
              </div>

              <div>
                <h4 className='left-text'>Estimated Revenue</h4>
                {stats && stats.revenues.map((revenue, i) =>
                  <div key={i}>
                    <Cell.Body>
                      <Cell.Line>
                        {revenue.sku} &nbsp; ${revenue.revenue} &nbsp; ({revenue.count})
                      </Cell.Line>
                    </Cell.Body>
                  </div>
                )}
              </div>
            </div>
          </>

          <div>
            <h2 className='left-text'>List</h2>

            <div className='navigation-tabs'>
              {Object.keys(tabs).map((tab, i) =>
                <NavigationTab
                  key={i}
                  text={tab}
                  isActive={currentTab === tab}
                  onClick={() => {
                    if (currentTab !== tab) {
                      setTab(tab)
                    }
                  }}
                />
              )}
            </div>

            {tabs[currentTab]}
          </div>

      </Layout>
    </div>
  )
}

export default AdminBoosts
