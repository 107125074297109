import React from 'react'
import * as moment from 'moment'
import {
  formatStat,
} from '../../shared'
import AdminBeatCell from './AdminBeatCell'
import AdminPostCell from './AdminPostCell'
import { Cell } from '../../components'

const formattedTimestamp = (timestamp) => moment(timestamp).utc().format('MMMM Do YYYY, HH:mm:ss')

const AdminBoostCell = ({boost, children}) => {
  const type = boost.item_type === 'BEAT' ? 'Beat' : 'Track'
  const createdAt = formattedTimestamp(boost.created_at)
  const endAt = formattedTimestamp(boost.end_at)
  return <>
    <Cell noline>
      <Cell.Body>
        <Cell.Line>
          <div>{type}</div>
          {boost.play_count &&
            <>
              <Cell.PlayCount>{formatStat(boost.play_count)}</Cell.PlayCount>
              <Cell.LikeCount>{formatStat(boost.like_count)}</Cell.LikeCount>
              <Cell.CommentCount>{formatStat(boost.comment_count)}</Cell.CommentCount>
            </>
          }
          {boost.item_type === 'BEAT' && <Cell.UseCount>{formatStat(boost.use_count)}</Cell.UseCount>}
        </Cell.Line>
        <Cell.Line>
          <div>{createdAt}</div>
        </Cell.Line>
        <Cell.Line>
          <div>{endAt}</div>
        </Cell.Line>
      </Cell.Body>
    </Cell>
    {boost.item_type === 'BEAT' ? <AdminBeatCell beat={boost.item} smallImage></AdminBeatCell> : <AdminPostCell post={boost.item} smallImage/>}
    {children}
  </>
}

export default AdminBoostCell
