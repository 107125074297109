import moment from 'moment'
import Cell from '../../components/Cell'
import { humanizedDuration, getUserToShow } from '../../shared'
import classNames from '../../widgets/util'
import authClient from '../../auth/authClient'

const ConversationCell = ({ conversation, navigateToConversation }) => {
  const currentUser = authClient.currentUser()
  const displayUser = getUserToShow(conversation)

  return <Cell onClick={() => navigateToConversation(conversation.id)}>
      <Cell.Image
        src={displayUser?.user_profile_images['200']}
        className='conversation_preview_profile-image'
        alt='profile picture'
      />
      <Cell.Body>
        <div className='conversation_preview_body'>
          <div>
            <Cell.Line>
              <Cell.LineTitle>
                {getUserToShow(conversation).username}
              </Cell.LineTitle>
              <div className='conversation_preview_time'>
                {humanizedDuration(
                  moment(conversation.newest_message.created_at),
                  moment()
                )}
              </div>
            </Cell.Line>
            <Cell.Line>
              <p className='conversation_preview_message'>
                {conversation.newest_message.data}
              </p>
            </Cell.Line>
          </div>
          <div className={classNames(conversation.last_read_message_id !== conversation.newest_message.id && conversation.newest_message.user_id !== currentUser.user_id && 'conversation_preview_unread-dot')} />
        </div>
      </Cell.Body>
    </Cell>

}

export default ConversationCell
