import React from 'react'
import pauseImg from '../images/player-pause.svg'
import playImg from '../images/player-play.svg'
import {
  niceIso8601String,
  formattedDuration,
  formatStat,
  niceBlockedMessage,
  keyScales,
} from '../shared'
import PublicUserLink from './PublicUserLink'
import PublicItemLink from './PublicItemLink'
import Cell from './Cell'

const BeatCell = ({audience, beat, onShare, onEdit, onDelete, onBoost, onPlayPause, isPlaying, didHide}) => {

  const actions = []
  if (onDelete) { actions.unshift({ title: 'Delete', onClick: () => { onDelete(beat, didHide) } }) }
  if (!beat.is_blocked) {
    if (onEdit) { actions.unshift({ title: 'Edit', onClick: () => { onEdit(beat) } }) }
    if (onShare) { actions.unshift({ title: 'Copy Link', onClick: () => { onShare(beat) } }) }
    if (onBoost) { actions.unshift({ title: 'Boost', onClick: () => { onBoost(beat) } }) }
  }

  return (
    <Cell>
      <div className='position-relative'>
        {isPlaying && onPlayPause && <span className='play-pause-overlay' onClick={() => onPlayPause(beat)}>
          <img src={isPlaying(beat) ? pauseImg : playImg} alt='play' />
        </span>}
        <Cell.Image src={beat.images['100']} alt='' />
      </div>

      <Cell.Body>
        <Cell.Line>
          {audience === 'public' ?
            <PublicItemLink name={beat.beat_name} type={'BEAT'} id={beat.id} /> :
            <Cell.LineTitle>{beat.beat_name}</Cell.LineTitle>
          }
          {beat.is_blocked && <Cell.LineImportant>{niceBlockedMessage(beat.is_blocked)}</Cell.LineImportant>}
          {!beat.is_blocked && beat.is_featured && <Cell.LineFeatured />}
        </Cell.Line>

        <Cell.Line>
          {audience === 'public' ?
            <PublicUserLink small user_id={beat.producer_user_id} username={beat.producer_name} /> :
            <div>{niceIso8601String(beat.created_at)}</div>
          }
          <div>•</div>
          <div>{formattedDuration(beat.duration_seconds)}</div>
        </Cell.Line>

        <Cell.Line>
          {<Cell.UseCount>{formatStat(beat.use_count)}</Cell.UseCount>}
          {<Cell.PlayCount>{formatStat(beat.play_count)}</Cell.PlayCount>}
          {<Cell.LikeCount>{formatStat(beat.like_count)}</Cell.LikeCount>}
          {<Cell.CommentCount>{formatStat(beat.comment_count)}</Cell.CommentCount>}
          {beat.buy_license_url && <Cell.BuyLicense />}
        </Cell.Line>

        <Cell.Line>
          <Cell.MusicNote>{beat.genre}</Cell.MusicNote>
          {beat.bpm != null && <div>{parseInt(beat.bpm, 10)} bpm</div>}
          {beat.key != null && <div>{keyScales.find(x => x.id === beat.key).value}</div>}
        </Cell.Line>
      </Cell.Body>
      {actions.length > 0 && <Cell.OverflowButton actions={actions} />}
    </Cell>
  )
}

export default BeatCell
