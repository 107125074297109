import { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import * as moment from 'moment'
import { Button, Layout, AlertContext } from '../widgets'
import * as routes from '../routes'
import {
  AdminBeatCell,
  AdminCommentCell,
  AdminConversationCell,
  AdminPostCell,
  AdminPlaylistCell,
  ImageBlockButton,
  UserBanInput,
  ItemReportCell,
} from './components'
import { ProducerProfileContent } from '../user/components'
import {
  niceIso8601String,
  Loading,
  withRouter,
} from '../shared'
import { NavigationTab, PaginatedList } from '../components'
import { AdminUserBoosts } from './feed'

const AdminCreatorProfile = (props) => {

  const [state, replaceState] = useState({
    loading: true,
    user: null,
    tab: 'Details',
    banUntil: null,
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  const { showAlertConfirm, handleError } = useContext(AlertContext)

  const getUserId = () => {
    return props.router.params.userId
  }

  const tabs = {
    'Details': <ProducerProfileContent.AdminDetails {...state.user} />,
    'Beats': <PaginatedList
      key={'beats'}
      loadUrl={routes.adminBeatUserUrl}
      loadParams={{'user_id': getUserId()}}
      featuredUrl={routes.adminBeatFeaturedUrl}
      cell={AdminBeatCell}
      cellItemPropName={'beat'}
    />,
    'Tracks': <PaginatedList
      key={'posts'}
      loadUrl={routes.adminPostUserUrl}
      loadParams={{'user_id': getUserId()}}
      featuredUrl={routes.adminPostFeaturedUrl}
      cell={AdminPostCell}
      cellItemPropName={'post'}
    />,
    'Playlists': <PaginatedList
      key={'playlists'}
      loadUrl={routes.adminPlaylistUserUrl}
      loadParams={{'user_id': getUserId()}}
      featuredUrl={routes.adminPlaylistFeaturedUrl}
      cell={AdminPlaylistCell}
      cellItemPropName={'playlist'}
    />,
    'Boosts': <AdminUserBoosts />,
    'Comments': <PaginatedList
      key={'comments'}
      loadUrl={routes.dynamic(routes.adminCommentByUserUrl, getUserId())}
      loadParams={{}}
      cell={AdminCommentCell}
      cellItemPropName={'comment'}
    />,
    'Conversations': <PaginatedList
      key={'conversations'}
      loadUrl={routes.adminListConversationsUrl}
      loadParams={{
        'user_id': getUserId()
      }}
      cell={AdminConversationCell}
      cellItemPropName={'conversation'}
    />,
    'Reports': <PaginatedList
      loadUrl={routes.adminModerationReportItemUrl}
      loadParams={{
        item_type: 'USER',
        item_id: getUserId(),
      }}
      cell={ItemReportCell}
      cellItemPropName={'report'}
    />,
    'For You': <PaginatedList
      key={'foryou'}
      loadUrl={routes.adminPostForYouUrl}
      loadParams={{'user_id': getUserId()}}
      cell={AdminPostCell}
      cellItemPropName={'post'}
    />,
  }

  useEffect(() => {
    reload()
  }, [getUserId()])

  const reload = () => {
    setState({ loading: true })
    getProfile(getUserId())
  }

  const getProfile = (userId) => {
    axios.get(routes.adminCreatorProfileUrl, {
      params: {
        requested_user_id: userId
      }
    }).then((response) => {
      setState({
        user: response.data
      })
    }).catch((error) => {

      if (error?.response?.status !== 404) {
        handleError(error)
      }
    }).finally(() => {
      setState({ loading: false })
    })
  }

  const onBanUntilChanged = (value) => {
    setState({ banUntil: value ? moment(value, 'YYYY-MM-DD').toISOString() : null })
  }

  const onSubmitBan = () => {
    if (state.user.is_banned) {
      // unban
      showAlertConfirm({
        title: 'Unban',
        message: `Are you sure you want to unban ${state.user.username}?`,
        callback: () => {
          setState({ loading: true })
          axios.delete(routes.adminModerationUserBanUrl, {
            data: {
              user_id: getUserId()
            }
          })
            .catch(handleError)
          // reload after some delay to let item blocks propagate
            .finally(() => { setTimeout(reload, 1000) })
        }
      })
    } else {
      // upsert ban
      const banTimeMessage = state.banUntil ? `until ${niceIso8601String(state.banUntil)}` : 'indefinitely'
      showAlertConfirm({
        title: 'Ban',
        message: `Are you sure you want to ban ${state.user.username} ${banTimeMessage}?`,
        callback: () => {
          setState({ loading: true })
          axios.post(routes.adminModerationUserBanUrl, {
            user_id: getUserId(),
            ban_until: state.banUntil,
          })
            .catch(handleError)
          // reload after some delay to let item blocks propagate
            .finally(() => { setTimeout(reload, 1000) })
        }
      })
    }
  }

  const onSubmitImageBlock = () => {
    setState({ loading: true })
    axios.put(routes.adminModerationBlockImageUrl, {
      id: getUserId(),
      type: 'USER',
      block: !state.user.is_profile_image_blocked,
    })
      .catch(handleError)
      .finally(reload)
  }

  const onDeleteAllComments = () => {
    showAlertConfirm({
      title: 'Delete Comments',
      message: `Are you sure you want to delete all comments for ${state.user.username}?`,
      callback: () => {
        setState({ loading: true })
        axios.delete(routes.dynamic(routes.adminCommentByUserUrl, getUserId()))
          .catch(handleError)
        // reload after some delay to let deletions propagate
          .finally(() => { setTimeout(() => { reload() }, 1000) })
      }
    })
  }

  if (state.loading) {
    return <Loading />
  }

  if (!state.user) {
    return (
      <div className='body-container'>
        <Layout>
          <div>
            I tried so hard<br/>
            And got so far<br/>
            But in the end<br/>
            There's no user with id {getUserId()}<br/>
          </div>
        </Layout>
      </div>
    )
  }

  return (
    <div className='body-container'>
      <Layout>
        <ProducerProfileContent {...state.user} {...{audience: 'admin'}} />
        <UserBanInput
          onChange={onBanUntilChanged}
          onSave={onSubmitBan}
          isBanned={state.user.is_banned}
        />
        <br/>
        <ImageBlockButton.Profile
          onSave={onSubmitImageBlock}
          isBlocked={state.user.is_profile_image_blocked}
        />
        <div className='moderator-content'>
          <div className='navigation-tabs'>
            {Object.keys(tabs).map((tab, i) =>
              <NavigationTab
                key={i}
                text={tab}
                isActive={state.tab === tab}
                onClick={() => {
                  if (state.tab !== tab) {
                    setState({ tab: tab })
                  }
                }}
              />
            )}
          </div>
          {state.tab === 'Comments' &&
            <Button className='small red float-left' onClick={onDeleteAllComments}>Delete All Comments</Button>
          }
          {tabs[state.tab]}
        </div>
      </Layout>
    </div>
  )
}

export default withRouter(AdminCreatorProfile)
