import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Input, Layout, AlertContext } from '../widgets'
import * as routes from '../routes'
import globals from '../globals'
import {
  AdminCommentCell,
  AdminPostCell,
  FilterPicker,
  ItemReportCell,
} from './components'
import {
  arrayDiff,
  Loading,
  filenameFromSignedUrl,
  withRouter,
} from '../shared'
import { NavigationTabs, PaginatedList } from '../components'

const AdminEditPost = (props) => {
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(true)

  const [state, replaceState] = useState({
    post: null,
    title: null,
    effectUid: null,
    genreId: null,

    blocks: [],
    existingBlockReasons: [],
    updatedBlockReasons: [],
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  useEffect(() => {
    load()
  }, [])

  const { showAlert, showAlertConfirm, handleError } = useContext(AlertContext)

  const postId = () => {
    return props.router.params.postId
  }

  const load = () => {
    setLoading(true)
    axios.get(
      routes.dynamic(routes.adminPostUrl, postId())
    ).then((response) => {
      const post = response.data

      axios.get(
        routes.adminModerationBlockUrl, {
          params: {
            item_type: 'POST',
            item_id: postId(),
          }
        }
      ).then((response) => {
        const blocks = response.data.data
        const reasons = blocks.map((block) => block.reason)

        setState({
          post: post,
          title: post.title,
          effectUid: post.effect_uid,
          genreId: post.genre_id,

          blocks: blocks,
          existingBlockReasons: reasons,
        })
      }).catch((error) => {
        handleError(error)
      }).finally(() => {
        setLoading(false)
      })

    }).catch((error) => {
      handleError(error)
    })
  }

  const onFeature = () => {
    setLoading(true)
    axios.post(routes.adminPostFeaturedUrl, {
      id: state.post.id,
      featured: !state.post.is_featured,
    }).then(load).catch(handleError)
  }

  const onSubmitImageBlock = () => {
    setLoading(true)
    axios.put(routes.adminModerationBlockImageUrl, {
      id: postId(),
      type: 'POST',
      block: !state.post.is_image_blocked,
    }).catch(handleError)
      .finally(load)
  }

  const onConvertToBeat = () => {
    showAlertConfirm({ title: 'Convert to Beat', message: 'Are you sure you want to convert this post to a beat?', callback: () => {
      setLoading(true)
      axios.post(routes.adminPostConvertToBeatUrl, {
        post_id: postId()
      }).catch(handleError)
        .finally(() => props.router.navigate(-1))
    }})
  }

  const onRecognize = () => {
    axios.post(routes.adminPostModerationRecognizeUrl, {
      item_type: 'POST',
      item_id: postId(),
    }).catch(handleError)
      .finally(() => showAlert({ title: 'Running', message: 'Audio content recognition is now running, refresh this page in ~10 seconds to see the results.' }))
  }

  const parseBlocksDetail = (blocks) => {
    if (!blocks) {
      return null
    }
    return blocks.map(block => {
      try {
        const json = JSON.parse(block.details)
        if (json['acrid']) {
          return `
          Copyright Infringement
            acrid: ${json.acrid}
            Score: ${json.score}
            Label: ${json.label}
            Artists: ${json.artists.map(a => a.name).join(', ')}
            Album: ${json.album.name}
            Title: ${json.title}
            Release Date: ${json.release_date}
          `
        } else {
          return block.details
        }
      } catch (e) {
        return block.details
      }
    }).join('\n')
  }

  const handleChange = (key, value) => {
    setState({ [key]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setSubmitting(true)
    axios.put(routes.dynamic(routes.adminPostUrl, postId()),
      {
        title: state.title,
        effect_uid: state.effectUid,
        genre: state.genreId,
      }
    ).then(() => {
      const toCreate = arrayDiff(state.updatedBlockReasons, state.existingBlockReasons)
      const toDelete = arrayDiff(state.existingBlockReasons, state.updatedBlockReasons)
      const createRequests = toCreate.map((reason) => {
        return axios.post(routes.adminModerationBlockUrl, {
          item_type: 'POST',
          item_id: postId(),
          reason: reason,
        })
      })
      const deleteRequests = toDelete.map((reason) => {
        return axios.delete(routes.adminModerationBlockUrl, {
          data: {
            item_type: 'POST',
            item_id: postId(),
            reason: reason,
          }
        })
      })
      Promise.all(createRequests.concat(deleteRequests)).catch(handleError).then(load)
    }).catch(handleError)
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (loading) {
    return <Loading />
  }

  const blockDetail = parseBlocksDetail(state.blocks)

  return (
    <div className='body-container'>
      <Layout>
        <Layout.Header>
          {state.post.title}
        </Layout.Header>

        <div className='left-text'>
          {filenameFromSignedUrl(state.post.signed_track_url)}
        </div>

        <AdminPostCell post={state.post} noline>
          <AdminPostCell.EditActions
            post={state.post}
            onFeature={onFeature}
            onBlockImage={onSubmitImageBlock}
            onConvertToBeat={onConvertToBeat}
            onRunAcrCloud={onRecognize}
          />
        </AdminPostCell>

        <NavigationTabs tabs={{
          'Project Stats': <table className='left-text vfont-body-big'>
            <tbody>
              {!state.post.project_stats ? 'None' : Object.entries(state.post.project_stats).map(([key, value]) =>
                <tr><th className='width-200px color-text-light'>{key}</th><td>{String(value)}</td></tr>
              )}
            </tbody>
          </table>,

          'Edit': <Form layout='vertical' onSubmit={handleSubmit}>
            <div className='submit-track'>
              <div className='submit-track__right'>
                <Form.Item label='Title *'>
                  <Input required type='text' value={state.title} onChange={(event) => handleChange('title', event.target.value)} />
                </Form.Item>
                <Form.Item label='Effect'>
                  <select
                    defaultValue={state.effectUid}
                    onChange={(event) => handleChange('effectUid', event.target.value)}
                    className='ant-input form-select'
                  >
                    <option value=''>(None)</option>
                    {globals.storeModel.AudioEffectPacks.map((pack) =>
                      <optgroup label={pack.localized_name['en']} key={pack.localized_name['en']}>
                        {pack.AudioEffects.map((effect) =>
                          <option value={effect.uid} key={effect.uid}>{effect.localized_name['en']}</option>
                        )}
                      </optgroup>
                    )}
                  </select>
                </Form.Item>
                <Form.Item label='Genre'>
                  <select
                    defaultValue={state.genreId}
                    translate={'no'}
                    className='ant-input form-select'
                    onChange={(event) => handleChange('genreId', event.target.value)}
                  >
                    <option value=''>(None)</option>
                    {globals.genres.map((genre, index) => {
                      return <option key={index} className='select' value={genre.id}>{genre.value}</option>
                    })}
                  </select>
                </Form.Item>

                <FilterPicker.ItemBlock
                  onChange={(values) => handleChange('updatedBlockReasons', values)}
                  defaultValues={state.existingBlockReasons}
                />

                {blockDetail &&
                  <pre className='left-text font-12'>{blockDetail}</pre>
                }

                <Form.Button
                  htmlType='submit'
                  loading={submitting}
                >
                  Save
                </Form.Button>
              </div>
            </div>
          </Form>,

          'Comments': <PaginatedList
            key={'comments'}
            loadUrl={routes.commentListByItemUrl}
            loadParams={{
              'item_type': 'POST',
              'item_id': state.post.id,
            }}
            cell={AdminCommentCell}
            cellItemPropName={'comment'}
          />,

          'Reports': <PaginatedList
            key={'reports'}
            loadUrl={routes.adminModerationReportItemUrl}
            loadParams={{
              'item_type': 'BEAT',
              'item_id': state.post.id,
            }}
            cell={ItemReportCell}
            cellItemPropName={'report'}
          />
        }}/>
      </Layout>
    </div>
  )
}

export default withRouter(AdminEditPost)
