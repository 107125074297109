import { useState, useEffect, useRef, useContext } from 'react'
import axios from 'axios'
import moment from 'moment'
import { withRouter, Loading, getUserToShow, formattedDate, formattedTime } from '../shared'
import { Layout , AlertContext, Button } from '../widgets'
import { PaginatedList } from '../components'
import * as routes from '../routes'
import Cell from '../components/Cell'
import authClient from '../auth/authClient'
import sendMessage from '../images/message-send.svg'
import sadBoi from '../images/sad-boi.svg'

const FullConversationView = (props) => {
  const listRef = useRef(null)
  const textareaRef = useRef(null)
  const textareaContainerRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [displayUser, setDisplayUser] = useState(null)
  const [conversationMembers, setConversationMembers] = useState([])
  const convserationId = props.router.params.id
  const [messages, setMessages] = useState([])
  const [newestMessageId, setNewestMessageId] = useState(null)
  const [newMessage, setNewMessage] = useState('')
  const [newestMessageTimestamp, setNewestMessagesTimestamp] = useState(Date.now())
  const [isConversationBlocked, setIsConversationBlocked] = useState(false)
  const [isUserBlocked, setIsUserBlocked] = useState(false)

  const { handleError, showAlertConfirm } = useContext(AlertContext)

  const currentUser = authClient.currentUser()
  useEffect(() => {
    axios.get(routes.dynamic(routes.getConversationInfoUrl, convserationId)).then((response) => {
      const conversation = response.data
      setConversationMembers(conversation.members)
      setDisplayUser(getUserToShow(conversation))
      setNewestMessageId(conversation.newest_message?.id)
      setIsConversationBlocked(conversation.is_blocked)

      setLoading(false)
    }).catch(handleError)
  }, [convserationId])

  useEffect(() => {
    if (!displayUser || displayUser.user_id === currentUser.user_id) {
      return
    }

    axios.get(routes.blockUserUrl, { params: { user_id: displayUser.user_id } }).then((response) => {
      setIsUserBlocked(response.data)
    }).catch(handleError)
  }, [displayUser])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const calculateUpdatedMessages = (messages) => {
    let interval = moment.duration(15, 'minutes')
    for (let i = 0; i < messages.length; i++) {
      let prevMsg = i + 1 >= messages.length ? null : messages[i + 1]
      messages[i].showTimestamp = !prevMsg || prevMsg?.created_at && moment(messages[i].created_at).diff(moment(prevMsg.created_at), 'minutes') > interval.asMinutes()
      messages[i].showUserIcon = (messages[i].user_id !== prevMsg?.user_id || messages[i].showTimestamp) && messages[i].user_id !== currentUser.user_id
      messages[i].fromSelf = messages[i].user_id === currentUser.user_id
    }

    return messages
  }

  const handleSendMessage = async (message) => {
    if (!/\S/.test(message)) {
      return
    }

    await axios.post(routes.dynamic(routes.getFullConversationUrl, convserationId), { data: message})
      .then(() => {
        setNewestMessagesTimestamp(Date.now())
        setNewMessage('')
      })
      .catch(handleError)
  }

  if (loading) {
    return <Loading/>
  }

  const autoResize = () => {
    if (textareaContainerRef.current && textareaRef.current) {
      textareaContainerRef.current.style.height = 'auto'
      textareaContainerRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }

  const onMessageRead = (messageId) => {
    if (messageId === newestMessageId) {
      axios.put(routes.dynamic(routes.markMessageReadUrl, convserationId, messageId)).catch(handleError)
    }
  }

  const handleBlockUser = () => {
    if (isUserBlocked) {
      axios.delete(routes.blockUserUrl, {
        data: {
          user_id: displayUser.user_id,
        }
      }).then(() => {
        setIsUserBlocked(false)

        axios.get(routes.dynamic(routes.getConversationInfoUrl, convserationId)).then((response) => {
          const conversation = response.data
          setIsConversationBlocked(conversation.is_blocked)
        }).catch(handleError)
      }).catch(handleError)
    } else {
      axios.post(routes.blockUserUrl, {
        user_id: displayUser.user_id,
      }).then(() => {
        setIsUserBlocked(true)
        setIsConversationBlocked(true)
      }).catch(handleError)
    }
  }

  const handleArchiveConversation = () => {
    axios.put(routes.dynamic(routes.archiveConversationUrl, convserationId)).then(() => {
      props.router.navigate(routes.producerConversationPath)
    }).catch(handleError)
  }

  return <Layout>
    <div className='conversation_container'>
    <Layout.Header className='conversation_container_header'>
      <div className='conversation_container_header_spacer'></div>
      <h5 className='conversation_container_header_username'>{displayUser?.username}</h5>
      <div className='conversation_container_header_actions'>
        <Cell.OverflowButton actions={[{title: isUserBlocked ? 'Unblock' : 'Block', onClick: () => showAlertConfirm({
          title: `${isUserBlocked ? 'Unblock' : 'Block'} ${displayUser.username}?`,
          message: isUserBlocked ? 'They will now be able to comment on and favorite your posts.' : 'They will be able to view your posts, but not comment on or favorite them.',
          callback: () => handleBlockUser()
        })},
        {
          title: 'Report',
          onClick: () => props.router.navigate(routes.dynamic(routes.producerReportPath, 'USER', displayUser.user_id))
        },
        {
          title: 'Archive',
          onClick: () => handleArchiveConversation()
        }
        ]} />
      </div>
    </Layout.Header>
    <div ref={listRef} className='conversation_messages-container'>
      <PaginatedList
        key={`messages-${newestMessageTimestamp}`}
        loadUrl={routes.dynamic(routes.getFullConversationUrl, convserationId, '/message')}
        cell={MessageCell}
        cellItemPropName={'message'}
        itemsUpdated={(messages) => setMessages(calculateUpdatedMessages(messages))}
        cellAdditionalProps={{
          updatedMessages: messages,
          conversationMembers,
          onMessageRead,
        }}
        reverse={true}
        onRender={() => {requestAnimationFrame(() => {
          if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight
          }
        })}}
        emptyStateOverride={<div className='messages-empty-state'>
          <img src={sadBoi} alt='sad boi' />
          <div>{`No conversation yet. Send a nice message to ${displayUser?.username}!`}</div>
        </div>}
      />
      </div>
    <div className='conversation_message_input-container'>
      <div className='conversation_message_input-bar' ref={textareaContainerRef}>
        <img src={currentUser.profile?.profile_pics['200']} className='conversation_message_profile-image'/>
        <textarea
          type='text'
          className='conversation_message_input'
          id='auto-resize-input'
          placeholder={isConversationBlocked ? 'Blocked' : 'New Message'}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              handleSendMessage(newMessage)
            }
          }}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onInput={autoResize}
          ref={textareaRef}
          disabled={isConversationBlocked}
          />
          <Button
            className='conversation_message_send-button'
            skipDefaultClass={true}
            onClick={() => handleSendMessage(newMessage)}>
              <img
                src={sendMessage}
                alt='send'
                title='Send message'
                disabled={isConversationBlocked}/>
          </Button>
        </div>
      </div>
    </div>
  </Layout>
}

const MessageCell = ({message, updatedMessages, conversationMembers, onMessageRead}) => {
  const hasReadRef = useRef(false)
  const messageToShow = updatedMessages.find((x) => x.id === message.id)
  if (!messageToShow) {
    return null
  }

  if (!hasReadRef.current) {
    hasReadRef.current = true
    onMessageRead(message.id)
  }

  const displayTime =
    <div className='conversation_message_header'>
      <p className='conversation_message_header_paragraph'>
        <b>
          {moment(messageToShow.created_at).isSame(moment(), 'day')
            ? 'Today'
            : formattedDate(messageToShow.created_at)}
        </b>
        <span className='conversation_preview_time'>
          {formattedTime(messageToShow.created_at)}
        </span>
      </p>
    </div>

  return <>
      {messageToShow.showTimestamp && displayTime}
      <Cell noline={true} cellClassName='conversation_message_cell'>
        {messageToShow.showUserIcon &&
          <Cell.Image
            src={
              conversationMembers.find(
                (x) => x.user_id === messageToShow.user_id
              )?.user_profile_images['200']
            }
            className='conversation_message_profile-image'
            alt='profile'
          />
        }
        <Cell.Body>
          <span
            className={`conversation_message ${
              messageToShow.fromSelf
                ? 'conversation_message_self'
                : 'conversation_message_other'
            } ${
              !messageToShow.showUserIcon && !messageToShow.fromSelf
                ? 'conversation_message_no-icon'
                : ''
            }`}
          >
            {messageToShow.data}
          </span>
        </Cell.Body>
      </Cell>
    </>

}

export default withRouter(FullConversationView)
